import * as React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Typography, TableBody,Grid, IconButton, TextField, CircularProgress, Box, Button, FormControl } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import axios from 'axios'
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'notistack'
import { styled } from '@mui/material/styles';
import { ExpandMore, SearchOutlined, DeleteOutline, CloseOutlined } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import MiniDrawer from '../../../components/drawer'
import PersonIcon from '@mui/icons-material/Person';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >

                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function ViewLinks() {
//UseState's:
    const tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }
    var navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [link,setLink] = React.useState('')
    const [deletelink, setDeleteLink] = React.useState('')
    const [count, setCount] = React.useState(0)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [tagDetails, setTagDetails] = React.useState([])
    const [searchTerm, setSearchTerm] = React.useState('')
    const [deletemodel, setDeletemodel] = React.useState(false)
    const [deleteid, setDeleteId] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const[Tableloading,setTableloading]=React.useState(true)
    const[truebutton,setTruebutton]=React.useState(false)

//Hook's state:
    React.useEffect(()=>{
        GetLinks()
    },[])


//Get Links data:
    const GetLinks = () => {
        setTableloading(true)
        axios.get(process.env.REACT_APP_BASE_URL + 'SocailMediaLinks/GetSocialMediaLinks')
        .then((res)=>{
            setLink(res.data.SocialMediaLinks);
        })
        .catch((error)=>{
            console.log(error,"error in GetLinks");
        })
        .finally(()=>{
            setTimeout(() => {
                setTableloading(false);
            }, 1000);
        })
    }





// //To Delete Tag:
    const handleDelete = (data) => {
        setDeleteLink(data._id)
        setOpen(true)
    }

    const handleDeleteLink = async (id) => {
        setTruebutton(true)
        await axios.put(process.env.REACT_APP_BASE_URL + `SocailMediaLinks/deleteSocialMediaLink/${id}`)
        .then((res) => {
            handleClose()
            GetLinks()
            enqueueSnackbar("Social Media Links Deleted Successfully", { variant: 'success' })

        }).catch((err) => {
            console.log(err);
        })
        .finally(()=>{
            setTruebutton(false)
        })
    }


// //To Navigate to edit tag:
    const handleClose = () =>{
        setOpen(false)
    }

    const handleEdit = (item) => {
        navigate("/addsociallinks", { state: { item,from: 'edit' } });
    }






    return (
        <>
        <MiniDrawer menuOrder={ 21} />
        <Box sx={{ width: '90%',marginLeft:'250px' }}>
            <Paper sx={{ width: '100%', boxShadow: 'none' }} className='Datatable'>
            <Box className='heading-btns mt-20' style={{ textAlign: 'right',marginRight:'200px' }}>
                <Button variant="contained" className='theme-btn' onClick={()=>navigate('/addsociallinks')} >Add Links</Button>
            </Box>
                <TableContainer id='table' style={{marginTop:40}}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" className='tble'>
                        <TableHead className='DT-head'>
                            <TableRow className=''>
                                <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'><strong>Facebook</strong></TableCell>
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Instagram</strong> </TableCell>
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'><strong>Twitter</strong> </TableCell>
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'> <strong>Pinterest</strong> </TableCell>
                                <TableCell style={{ minWidth: 180 }} align="left" className='tbl_head'> <strong>Whatsapp</strong>  </TableCell>
                                <TableCell style={{ minWidth: 150 }} align="left" className='tbl_head'> <strong>Tiktok</strong>  </TableCell>
                                <TableCell style={{ minWidth: 150 }} align="center" className='tbl_head'> <strong>Action</strong>  </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                                    {    Tableloading ?
                                    (
                                        <TableRow>
                                    <TableCell colSpan={7} className='nodata' ><CircularProgress sx={{ color: '#F3714D',marginLeft:'580px' }} /> </TableCell>
                                        </TableRow>
                                       
                                                
                                        
                                    )
                                    :
                                    
                                    
                                    
                                    (link && link.length > 0 ? 
                                    (link.map((item,i)=>(
                                        <TableRow className='' >
                                        <TableCell align="center" className='DT-row'>
                                            {item.facebook?item.facebook:'-'}
                                            
                                        </TableCell>
                                        <TableCell className='DT-row' >{item.instagram?item.instagram:'-'}</TableCell>
                                        <TableCell className='DT-row'  >{item.twitter?item.twitter:'-'}</TableCell>
                                        <TableCell align="left" className='DT-row'  >{item.pinterest?item.pinterest:'-'}</TableCell>
                                       
                                       

                                        
                                        <TableCell align="left" className='DT-row' >{item.whatsapp?item.whatsapp:'-'}</TableCell>
                                        <TableCell align="left" className='DT-row' >{item.tiktok?item.tiktok:'-'}</TableCell>
                                        <TableCell align="center" className='DT-row' style={{ cursor: 'pointer' }} >
                                            <Box className="flex-center justify-content-center actionbtns">
                                                <Tooltip title="Edit"><Box className='a_tag' ><EditOutlinedIcon className='icons text-grey' onClick={()=>handleEdit(item)} /></Box></Tooltip>
                                                <Tooltip title="Delete"><Box ><DeleteOutline className='icons text-grey' onClick={()=>handleDelete(item)}  /></Box></Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    )))
                                    : (
                                        <TableCell colSpan={10} className='DT-row text-center semibold' align="center"> No data found </TableCell>
                                    ))}
                                    
                              
                        </TableBody>
                    </Table>
                </TableContainer>
               
            </Paper>
            <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" className='custom_modal'>
                <DialogTitle>{"All links will be deleted,would you like to delete?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn'>No</Button>
                     {
                            truebutton ?
                                <Button variant="contained" className='theme-btn'>Please wait...</Button>
                                :
                    <Button onClick={()=>handleDeleteLink(deletelink)} className='theme-btn'>Yes</Button>
                     }
                </DialogActions>
            </Dialog>
        </Box></>
    );
}

