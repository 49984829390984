import * as React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Typography, TableBody, IconButton, TextField, InputAdornment, Box, Button, FormControl, CircularProgress } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import axios from 'axios'
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack'
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { ExpandMore, SearchOutlined,CloseOutlined, DeleteOutline, UnfoldMore, HighlightOffRounded, SettingsInputComponent } from '@mui/icons-material';
import moment from 'moment'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import { header, headerformdata } from "../../../components/axiousheader"
import { faL, faSlash } from '@fortawesome/free-solid-svg-icons';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >

                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function DataTable() {
    const tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }
    var navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [deletearry, setDeleteArray] = React.useState([])
    const [count, setCount] = React.useState(0)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [podcastDetails, setPodcastDetails] = React.useState([])
    const [searchTerm, setSearchTerm] = React.useState('')
    const [deletemodel, setDeletemodel] = React.useState(false)
    const [deleteid, setDeleteId] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const [isDeleting,setIsDeleting] = React.useState(false)
    const[Tableloading,setTableloading]=React.useState(true)

    React.useEffect(() => {
        getPodcast()
    }, [page, rowsPerPage, searchTerm])

    const handleChangeStatus = async (e, data, type) => {
        console.log(type, 'typetypetypetype');
        // if (type === 'mega_event') {
        //     const body = {
        //         mega_event: e.target.checked,
        //     };
        //     try {
        //         const response = await axios.put(process.env.REACT_APP_BASE_URL + `Event/updateEvent/${data._id}`, body, { headers: headerlocal });
        //         getEvents();
        //     } catch (error) {
        //         enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
        //         console.error("Error while updating mega event", error);
        //     }
        // }
        if (type === 'Public') {
            const body = {
                public: e.target.checked,
            };
            try {
                const response = await axios.put(process.env.REACT_APP_BASE_URL + `podcast/updatePodcast/${data._id}`, body);
                getPodcast();
            } catch (error) {
                enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                console.error("Error while updating public", error);
            }
        }
        if (type === 'Primary') {
            const body = {
                primary: e.target.checked,
            };
            try {
                const response = await axios.put(process.env.REACT_APP_BASE_URL + `podcast/updatePodcast/${data._id}`, body);
                getPodcast();
            } catch (error) {
                enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                console.error("Error while updating primary event", error);
            }
        }
    };


    const handleChangePage = (event, newPage) => {
        console.log('New page:', newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getPodcast = () => {
        setTableloading(true)
        axios.get(process.env.REACT_APP_BASE_URL + `podcast/GetPodcast?size=${rowsPerPage}&page=${page}&search=${searchTerm}`).then((res) => {
            console.log(res.data.podcast, 'res.getPodcastDetails.list');
            setPodcastDetails(res.data.podcast)
            setCount(res.data.totalcount || 0);
        }).catch((err) => {
            console.log(err);
        })
        .finally(()=>{
            setTimeout(() => {
                setTableloading(false);
            }, 1000);
        })
    }


    const handleDeleteData = () => {
        setDeletemodel(true)
    }

    const handleDelete = async () => {
        setIsDeleting(true)
        await axios.put(process.env.REACT_APP_BASE_URL + `podcast/deletepodcast/${deleteid}`, {}, tokendata,).then((res) => {
            console.log(res.data, 'res.putPodcastDetails.list');
            handleClose()
            getPodcast()
            enqueueSnackbar("Podcast deleted successfully", { variant: 'success' })

        }).catch((err) => {
            console.log(err);
        }).finally(()=>{
            setIsDeleting(false)
        })
    }

    const EditData = (row) => {
        let obj = {
            data: row
        }
        console.log(obj,'obj');
        navigate("/editpodcast", { state: obj })
    }

    const handleOpen = (id) => {
        setDeleteId(id)
        setOpen(true)
    }

    const handleClose = () => {
        setDeleteId('')
        setOpen(false)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', boxShadow: 'none' }} className='Datatable'>
                <Toolbar className='toolbar comptoolbar'>

                    {deletearry && deletearry.length > 0 && <Button className='del-btn' onClick={handleDeleteData}>Delete</Button>}
                    <Box component='div' className='DTsearch mr-10'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10'>Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={searchTerm}
                                className='textfield search'
                                onChange={(e) => { setSearchTerm(e.target.value); setPage(0) }}
                                placeholder='Search Here...'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {searchTerm !== '' && (
                                                <IconButton  size="small">
                                                    <CloseOutlined className='fz-18' />
                                                </IconButton>
                                            )}
                                            <SearchOutlined className='fz-18' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>
                </Toolbar>
                <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-label="customized table" className='table'>
                        <TableHead className='table-head'>
                            <TableRow className='table-row '>
                                {/* <StyledTableCell style={{ minWidth: 150 }} align="left" className='TRow'></StyledTableCell> */}
                                <StyledTableCell style={{ minWidth: 150 }} align="left" className='TRow'><strong>Title</strong> </StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }} align="left" className='TRow'> <strong>Description</strong> </StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }} align="left" className='TRow'> <strong>Audio</strong> </StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }} align="center" className='TRow'> <strong>Action</strong>  </StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { Tableloading?
                            (
                                <TableRow>
                                <TableCell colSpan={7} className='nodata' ><CircularProgress sx={{ color: '#F3714D',marginLeft:'580px' }} /> </TableCell>
                                    </TableRow>
                            )
                            :
                            (podcastDetails && podcastDetails.length > 0 ? podcastDetails.map((row, i) => {

                                return (
                                    <StyledTableRow className='tbody-row' key={row._id}>
                                        <StyledTableCell component="th" scope="row" padding="none" className='DT-row'>{row.name ? row.name : '-'}</StyledTableCell>
                                        <StyledTableCell align="left" className='DT-row'>{row.description ? row.description.slice(0,50)+ "..." : '-'}</StyledTableCell>
                                        <StyledTableCell align="left" className='DT-row'>{row.audio ? row.audio : '-'}</StyledTableCell>
                                        <StyledTableCell align="center" className='DT-row'>
                                            <Tooltip title="Edit">
                                                <IconButton onClick={() => EditData(row)}>
                                                    <EditOutlinedIcon className='icons text-grey' />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton onClick={() => handleOpen(row._id)}>
                                                    <DeleteOutline className='icons text-grey' />
                                                </IconButton>
                                            </Tooltip>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            }) : <StyledTableRow><StyledTableCell colSpan={4} className='nodata'>No data found</StyledTableCell></StyledTableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />

            </Paper>
            <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" className='custom_modal'>
                <DialogTitle>{"Would you like to delete Podcast?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn'>No</Button>
                    <Button onClick={handleDelete} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

