import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom'

export default function AddTag() {
//UseState's:
    let tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem("userData"))
    const [truebutton, setTruebutton] = React.useState(false)
    let location = useLocation()
    var data = location.state ? location.state.result : null
    const [tag, setTag] = React.useState({ name: "", description: "" })
    const [error, setError] = React.useState({ name: "", description: "" })


//OnChange:
    let name, value;
    const handleTag = (e) => {
        const { name, value } = e.target;
        // Regular expression to allow only letters and spaces
        const hasAlphanumeric = /[a-zA-Z]/;

        if (name === 'name') {
            if (!hasAlphanumeric.test(value)) {
                setError({ ...error, name: "Tag name must contain at least one letter." });
            } else {
                setError({ ...error, name: "" });
            }
        }

        setTag({ ...tag, [name]: value });
    }



//Validation:
    const Validator = () => {
        const hasAlphanumeric = /[a-zA-Z]/;

        if (!tag.name) {
            setError({ name: "This field is required." })
            return false
        }

        if (!hasAlphanumeric.test(tag.name)) {
            setError({ ...error, name: "Tag name must contain at least one letter." });
            return false;
        }
        return true
    }


//Add Tag:
    const handleSubmit = async () => {
        var Valid = Validator()
        if (Valid) {
            setTruebutton(true)
            let body = {
                tagname: tag.name,
                description: tag.description,
                createdby: auth && auth._id
            }
            axios.post(process.env.REACT_APP_BASE_URL + 'tags/addTags', body, tokendata).then((res) => {
                setTruebutton(false);
                enqueueSnackbar("Tag added successfully", { variant: 'success' })
                navigate('/tag')
                console.log(res, "tags addedd");
            }).catch((err) => {
                setTruebutton(false);
                enqueueSnackbar(err && err.response && err.response.data.message ? err.response.data.message : "Somthing went wrong", { variant: 'error' })
                console.log(err);
            })
        }
    }

    return (
        <Box className='Addcompany'>
            <MiniDrawer menuOrder={1} submenuOrder={3} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Add Tag</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Tag Name <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Name'
                                                name="name"
                                                value={tag.name}
                                                onChange={handleTag}
                                            />
                                            {error.name && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.name}</p>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Description</Typography>
                                            <textarea
                                                className='textarea'
                                                placeholder='Description'
                                                name="description"
                                                value={tag.description}
                                                onChange={(e) => handleTag(e)}
                                                rows={4}
                                                cols={40}
                                                style={{ resize: 'none' }}  />

                                        </FormControl>
                                    </Grid>

                                </Grid>
                                {truebutton === false ?
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button onClick={handleSubmit} variant="contained" className='theme-btn'>Submit</Button>
                                    </Box> : truebutton === true &&
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='theme-btn'>Please Wait</Button>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}