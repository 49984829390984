import { Box, Typography } from '@mui/material'
import React from 'react'
import MiniDrawer from '../../../components/drawer'
import QnATable from './QnATable'

const Questionandanswerindex = () => {
  return (
    <>
    <Box className="companies dashboard">
				<MiniDrawer menuOrder={1} submenuOrder={5} />
				<Box component="section" className="contentWraper">
					<Box className="headingBox">
						<Typography
							component="h4"
							variant="h4"
							className="Submenu-page-heading formTitle"
						>
							Question And Answer
						</Typography>
					</Box>
					<Box className="whiteContainer">
						<QnATable/>
					</Box>
				</Box>
			</Box>
    </>
  )
}

export default Questionandanswerindex