import React, { useEffect, useRef } from 'react';
import { Box, Button, Card, FormControl, Grid, IconButton, Typography } from '@mui/material';
import { AddOutlined, Close } from '@mui/icons-material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
// import './community.css'
const Add_Video = ({ parentFunc,error,setErro }) => {
    const [video, setVideo] = React.useState([]);
    const [Error, setError] = React.useState({ video: error.video, image: error.img });

console.log(error);

console.log(error.img,"errroeeeeeee");
console.log(Error);

useEffect(()=>{
    
setError({image:error.img})
},[setError,error])


    const inputRef = useRef(null);
    const handleAddClick = () => {
        setVideo(prevVideo => [...prevVideo, { imgpreview: "", imgraw: "", vdopvw: '', vdoraw: '' }]);
    };

    const handleImageChange = (e, index) => {
        setError({image:""})
        setErro({img:""})
        const selectedFile = e.target.files[0];

        // Check if the file size exceeds 1 MB (in bytes)
        const maxSize = 1 * 1024 * 1024; // 1 MB in bytes
        if (selectedFile.size > maxSize) {
            setError({ ...error, image: 'Image should be less than 1 MB' });
            return; // Do not proceed further
        }

        setVideo(prevVideo => {
            const addVideo = [...prevVideo];
            addVideo[index] = {
                imgpreview: URL.createObjectURL(selectedFile),
                imgraw: selectedFile
            };
            return addVideo;
        });
    };


    // const handleImageChangeind = (e, index) => {
    //     const selectedFile = e.target.files[0];
    //     setVideo(prevVideo => {
    //         const updatedVideo = [...prevVideo];
    //         updatedVideo[index] = {
    //             imgpreview: URL.createObjectURL(selectedFile),
    //             imgraw: selectedFile,
    //         };
    //         return updatedVideo;
    //     });
    // };

    const resetThumbnailImage = (e, index) => {
        console.log(index, 'hhh');
        setVideo(prevVideo => {
            const removeImage = [...prevVideo];
            removeImage[index] = {
                ...removeImage[index],
                imgpreview: '',
                imgraw: '',
            };
            return removeImage;
        });
    }

    // const handleVideoChange = (e, index) => {
    //     const selectedFile = e.target.files[0];
    //     setVideo(prevVideo => {
    //         const updatedVideo = [...prevVideo];
    //         updatedVideo[index] = {
    //             ...updatedVideo[index],
    //             vdopvw: URL.createObjectURL(selectedFile),
    //             vdoraw: selectedFile,
    //         };
    //         return updatedVideo;
    //     });
    // };

    const handleVideoChange = (e, index) => {
        setError({ video: '' });
        setErro({video:''})
        const selectedFile = e.target.files[0];

        // Check if the file size exceeds 25 MB (in bytes)
        const maxSize = 25 * 1024 * 1024; // 25 MB in bytes
        if (selectedFile.size > maxSize) {
            // Show error message or handle the validation as needed
            setError({ video: 'Video should be less than 25 MB' });
            return; // Do not proceed further
        }

        setVideo(prevVideo => {
            const updatedVideo = [...prevVideo];
            updatedVideo[index] = {
                ...updatedVideo[index],
                vdopvw: URL.createObjectURL(selectedFile),
                vdoraw: selectedFile,
            };
            return updatedVideo;
        });
        
    };



    const handleRemoveVideo = (e, index) => {
        setVideo(prevVideo => {
            const updatedVideo = [...prevVideo];
            updatedVideo[index] = {
                ...updatedVideo[index],
                vdopvw: '',
                vdoraw: '',
            };
            return updatedVideo;
        });
    };

    useEffect(() => {
        parentFunc(video);
    }, [video]);


    return (
        <Box className="">
            <Box className=" ">
                {video.map((x, i) => (
                    <FormControl key={i} className='mb-20' fullWidth style={{ flexDirection: 'column' }}>
                        <Typography className='fz-13 label mb-10'>Video #{i + 1}</Typography>
                        <Box className="flex-center flex-wrap">
                            <Box className="mr-10 ">
                                {x.imgpreview === '' || x.imgpreview === undefined ?
                                    <Button className="multiple_upload_btn w100" variant="contained" component="label">
                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" />Thumbnail image </Box>
                                        <input accept={"image/png, image/jpg,image/jpeg"} onChange={(e) => handleImageChange(e, i)} hidden type="file" />
                                    </Button> :
                                    <Box className="form-box upload-img">
                                        <Card style={{ width: '200px' }}>
                                            {/* <IconButton color="primary" aria-label="upload picture" component="label" className='uploadImg-btn'>
                                                    <input hidden accept="image/png, image/jpg, image/jpeg" ref={inputRef} type="file" onChange={(e) => handleImageChangeind(e, i)} />
                                                    <AddOutlined />
                                                </IconButton> */}
                                            <Typography component='div' variant='div'>
                                                <Button className='close' onClick={(e) => resetThumbnailImage(e, i)}><Close /></Button>
                                            </Typography>
                                            <div className='imgcontent'>
                                                {x.imgpreview === '' || x.imgpreview === undefined ? null :
                                                    <img src={x.imgpreview} alt='' height={155} width={195} border={0} />
                                                }
                                            </div>
                                        </Card>
                                    </Box>
                                }
                                {Error.image && <p style={{ color: 'red' }} className="fz-13 m-0" >{Error.image}</p>}

                            </Box>

                            <Box className="">
                                <Box className="">
                                    {x.vdopvw === '' || x.vdopvw === undefined ?
                                        <Button className="multiple_upload_btn w100" variant="contained" component="label">
                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload video </Box>
                                            <input id="video-upload" accept="video/*" onChange={(e) => handleVideoChange(e, i)} hidden type="file" />
                                        </Button> :
                                        <Grid container columnSpacing={1} rowSpacing={1} className="">
                                            <Grid item xs={12} sm={12} md={6} lg={4} >
                                                <Box  >
                                                    <Button className='close_icon' onClick={(e) => handleRemoveVideo(e, i)}><Close /></Button>
                                                    <video controls className='' src={x.vdopvw} height={155} width={195} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }
                                </Box>
                                {error.video && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.video}</p>}
                            </Box>
                        </Box>
                    </FormControl>

                ))}
                <Box className="benefit-btn">
                    <Button onClick={handleAddClick} className='theme-btn'>+ Add Video</Button>
                </Box>
            </Box>
        </Box >
    );
};

export default Add_Video;
