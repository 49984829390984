import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MiniDrawer from '../../../components/drawer';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, RadioGroup, Radio, TextField, Typography, FormControlLabel, Input, OutlinedInput, Checkbox, ListItemText, Dialog, Slide } from '@mui/material'
import { Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Switch from '@mui/material/Switch';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useLocation } from 'react-router-dom'
import { Editor } from 'react-draft-wysiwyg';
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EmailEditor from "react-email-editor";
import DOMPurify from 'dompurify';
import Sample from './sample.json'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function BpRadio(props) {
	return (
		<Radio
			sx={{
				'&:hover': {
					bgcolor: 'transparent',
				},
			}}
			disableRipple
			color="default"
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			{...props}
		/>
	);
}
const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: '50%',
	width: 16,
	height: 16,
	boxShadow:
		theme.palette.mode === 'dark'
			? '0 0 0 1px rgb(16 22 26 / 40%)'
			: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
	backgroundImage:
		theme.palette.mode === 'dark'
			? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
			: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto rgba(19,124,189,.6)',
		outlineOffset: 2,
	},
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
	},
}));

const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#f3714d',
	backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before': {
		display: 'block',
		width: 16,
		height: 16,
		backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: '#106ba3',
	},
});

const IOSSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 40,
	height: 20,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 0,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(20px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '6px solid #fff',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color:
				theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 18.5,
		height: 18.5,
	},
	'& .MuiSwitch-track': {
		borderRadius: 18.5 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
	display: 'flex',
	height: 22,
	alignItems: 'center',
	...(ownerState.active && {
		color: '#784af4',
	}),
	'& .QontoStepIcon-completedIcon': {
		color: '#784af4',
		zIndex: 1,
		fontSize: 18,
	},
	'& .QontoStepIcon-circle': {
		width: 8,
		height: 8,
		borderRadius: '50%',
		backgroundColor: 'currentColor',
	},
}));

function QontoStepIcon(props) {
	const { active, completed, className } = props;
	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? (
				<Check className="QontoStepIcon-completedIcon" />
			) : (
				<div className="QontoStepIcon-circle" />
			)}
		</QontoStepIconRoot>
	);
}

QontoStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor:
				'#f3714d',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor:
				'#f3714d',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor:
			theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
	zIndex: 1,
	color: '#fff',
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
		backgroundColor:
			'#f3714d',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	}),
	...(ownerState.completed && {
		backgroundColor:
			'#f3714d',
	}),
}));

function ColorlibStepIcon(props) {
	const { active, completed, className } = props;

	const icons = {
		1: <ListAlt />,
		2: <CompareIcon />,
		3: <Dvr />,
	};
	return (
		<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
			{icons[String(props.icon)]}
		</ColorlibStepIconRoot>
	);
}

ColorlibStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node,
};

const steps = ['Basic Information', 'Blog Information', 'Blog Description'];

export default function UpdateBlogs() {
	const headerlocal = {
		'Content-Type': 'application/json',
		"Access-Control-Allow-Origin": "*",
		Authorization: localStorage.getItem("token")
	}
	const headerformdata = {
		'Content-Type': 'multipart/form-data',
		Authorization: localStorage.getItem("token")
	}
	const [activeStep, setActiveStep] = React.useState(0);
	const navigate = useNavigate()
	const location = useLocation()
	const auth = JSON.parse(localStorage.getItem('userData'))
	const { enqueueSnackbar } = useSnackbar()
	const [publishername, setPublishername] = useState(location.state.data.published_by);
	const [publishError, setPublishError] = useState('');
	const [blog, setBlog] = React.useState(location.state.data.Blog_title)
	const [truebutton, setTruebutton] = React.useState(false)
	const [error, setError] = React.useState({ eventName: "", organizer: "", fromTime: "", toTime: "", video: "" })
	const [data, setData] = React.useState([])
	const [categoryId, setCategoryId] = React.useState(location.state.data.category_id)
	const [subcategoryId, setSubCategoryId] = React.useState(location.state.data.subcategory_id)
	const [catError, setCatError] = React.useState('')
	const [subcatError, setSubCatError] = React.useState('')
	const [imageError, setImageError] = React.useState('')
	const [imageAddError, setImageAddError] = React.useState('')
	const [state1, setState1] = React.useState(location.state.data.additionalimages)
	const [state2, setState2] = React.useState(location.state.data.additionalimages)
	const [statesingle, setStatesingle] = React.useState([...[], location.state.data.image])
	const [statesingle2, setStatesingle2] = React.useState([...[], location.state.data.image])
	const [description, setDescription] = React.useState(location.state.data.shortdescription)
	const [richeditor, setRichtor] = React.useState(location.state.data.Description)
	const [descJson, setDescJson] = React.useState(location.state.data.DescriptionJSON)

	const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
	const dataofeditor = `${richeditor}`
	const [selectedVideo, setSelectedVideo] = useState([...[], location.state.data.Video]);
	const [videoPreviewUrl, setVideoPreviewUrl] = useState([...[], location.state.data.Video]);
	const [recordings, setRecordings] = React.useState([])
	const [existingimages, setExistingimages] = React.useState(location.state.data.additionalimages)
	const [value, setValue] = React.useState(dayjs(location.state.data.Date));
	const emailEditorRef = useRef({ editor: {} });

	// const [isToggled, setToggled] = React.useState(location.state.data.publish)
	const [isPrimary, setPrimary] = React.useState(location.state.data.primary)
	const [isPublic, setPublic] = React.useState(location.state.data.public)
	const [selectGallert, setSelectGallery] = useState(location.state.data.gallery)
	const [teacher, setTeacher] = React.useState([])
	const [school, setSchool] = React.useState([])
	const [studio, setStudio] = React.useState([])
	const [isToggledapprove, setToggledapprove] = React.useState(location.state.data.approve_blogs)


  console.log(location.state.data);
  

   const [user, setUser] = React.useState(
          {
            instagram: location.state.data.instagram?location.state.data.instagram:'',
            facebook: location.state.data.facebook?location.state.data.facebook:'',
            twitter: location.state.data.twitter?location.state.data.twitter:"",
            youtube: location.state.data.youtube ? location.state.data.youtube:""
            , pinterest: location.state.data.pinterest?location.state.data.pinterest:'',
            tiktok: location.state.data.
                tiktok ? location.state.data.
                tiktok : ''

          })

	// const products = [
	// 	{
	// 		id: 1,
	// 		image:
	// 			"https://img.thriftbooks.com/api/images/i/m/B4380A756292C0D50D92213A920E5A9E6C1DD713.jpg",
	// 		title: "The Great Believers",
	// 		description:
	// 			"A page turner... An absorbing and emotionally riveting story about what it's like to live during times of crisis. The New York Times Book Review A dazzling new novel of friendship and redemption in the face of tragedy and loss set in 1980s Chicago and contemporary Paris In 1985, Yale Tishman, the development director for an art gallery in Chicago, is about to pull off an amazing coup, bringing in an extraordinary collection of 1920s paintings as a gift to the gallery.",
	// 	},
	// 	{
	// 		id: 2,
	// 		image:
	// 			"https://img.thriftbooks.com/api/images/i/m/F0836F0E91E904515A5A47078C555303CCC67351.jpg",
	// 		title: "The Hundred-Year House",
	// 		description:
	// 			"From the acclaimed author of The Great Believers, an original, mordantly witty novel about the secrets of an old-money family and their turn-of-the-century estate, Laurelfield. Meet the Devohrs: Zee, a Marxist literary scholar who detests her parents' wealth but nevertheless finds herself living in their carriage house; Gracie, her mother, who claims she can tell your lot in life by looking at your teeth; and Bruce, her step-father, stockpiling supplies for the Y2K apocalypse and perpetually late for his tee time. ",
	// 	},
	// ];
	const unlayerSampleJSON = {
		"body": JSON.parse(descJson),
	}
	const emailJSON = unlayerSampleJSON;

	const onLoad = () => {
		emailEditorRef.current.editor.loadDesign(emailJSON);
	};
	const handleEditor = (e) => {
		const unlayer = emailEditorRef.current?.editor;
		unlayer?.exportHtml((data) => {
			const { design, html } = data;
			const sanitizedHtml = DOMPurify.sanitize(html);
			setDescJson(JSON.stringify(design.body))
			enqueueSnackbar('Saved changes', { variant: 'info' });
		})
	}


  // Teacher**

  const [teacherUpdated, setTeacherUpdated] = React.useState(location.state.data.teacher)
  const [selectedTeacher, setSelectedTeacher] = React.useState(location.state.data.teacher);
	const [teacherDataUpdated, setTeacherDataUpdated] = React.useState([])


	const getTeacher = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getTeachersListForBlogs`, { headers: headerlocal });
			setTeacher(response.data.teachers);
			setTeacherDataUpdated(response.data.teachers)
		} catch (error) {
			console.log(error.response, "error");
		}
	}

  React.useEffect(() => {
		getTeacher()
	}, [])

  console.log(teacherDataUpdated)



	// const [selectedTeacher, setSelectedTeacher] = React.useState(() => {
	// 	const arrVal = []
	// 	const teacherArr = location.state.data.teacher
	// 	if (teacherArr.length > 0) {
	// 		teacherArr.map((item) => {
	// 			arrVal.push(item.username, item.email)
	// 		})
	// 	}
	// 	return arrVal
	// });

  
  


	React.useEffect(() => {
		getTeacherSelected();
	}, [selectedTeacher, teacherDataUpdated])

	function getTeacherSelected() {
    console.log(selectedTeacher)
		var array = []
		// const selectedTeachers = teacherDataUpdated.filter(content => selectedTeacher === content.username);
		// const selectedTeachers = teacherDataUpdated.filter(content => selectedTeacher === content.username);
    const selectedTeachers = teacherDataUpdated.filter((content) => {
      if(typeof selectedTeacher === "object" ){
        console.log("array")
        return selectedTeacher[0]?.username === content.username
      }
      else{
        return content.username  === selectedTeacher   
      }
    }) ;
    console.log(selectedTeachers)
    selectedTeachers && selectedTeachers.map((item, i) => {
			var val = { username: item.username, user_id: item._id }
			Object.assign(item, val)
			array.push(val)
		})
    console.log(array)

		setTeacherUpdated(array)
	}

	const handlechangeTeacherSelected = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
		const {
			target: { value },
		} = event;
		console.log(value)
		setSelectedTeacher(
			typeof value === 'string' ? value : value.split(','),
		);
		getTeacherSelected()
	}

  console.log(teacherUpdated)
  console.log(teacher)
  

	React.useEffect(() => {
		getCategory()
	}, [])

	React.useEffect(() => {
		getSubCategory()
	}, [categoryId])

	const getCategory = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, { headers: headerlocal }).then((res) => {
			setRecordings(res.data.category)
		}).catch((error) => {
			console.log(error.response, "error");
		})
	}

	const getSubCategory = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `SubCategory/GetSubcategorybasedonCategoryId?category_id=${categoryId}`, { headers: headerlocal })
			.then((res) => {
				setData(res.data.subcategory)
			}).catch((error) => {
				console.log(error.response, "error");
			})
	}
	React.useEffect(() => {
		if (dataofeditor) {
			try {
				const contentState = stateFromHTML(dataofeditor);
				setEditorState(EditorState.createWithContent(contentState));
			} catch (error) {
				console.error("Error during HTML to ContentState conversion:", error);
			}
		}
	}, [dataofeditor]);
	React.useEffect(() => {
		convertContentToHTML();
	}, [editorState]);

	const [convertedContent, setConvertedContent] = React.useState(null);
	const handleEditorChange = (newEditorState) => {
		setEditorState(newEditorState);
	}
	const convertContentToHTML = () => {
		const currentContent = editorState.getCurrentContent();
		const currentContentAsHTML = stateToHTML(currentContent);
		setConvertedContent(currentContentAsHTML)
	}

	const handleRemoveRestart = () => {
		setSubCategoryId('')
	}
	const maxFileSizeMB = 1; // Maximum file size allowed in MB

	const validateFileSize = (file) => {
		const fileSizeMB = file.size / (1024 * 1024); // Calculate file size in MB
		return fileSizeMB <= maxFileSizeMB;
	};

	const multipleImgChangeMultiple = (e, index) => {
		setImageAddError('');
		var fileObj = e.target.files;
		var fileArray = [];
		var filepath = [];

		for (let i = 0; i < fileObj.length; i++) {
			if (validateFileSize(fileObj[i])) {
				fileArray.push(URL.createObjectURL(fileObj[i]));
				filepath.push(fileObj[i]);
			} else {
				setImageAddError('Image size should be less than 1 MB.');
			}
		}

		// Continue with state updates only for valid files
		if (fileArray.length > 0) {
			var data = state1.filter(x => x != null);
			var data1 = state2.filter(x => x != null);
			setState1(data);
			setState2(data1);
			setState1(prevValue => prevValue.concat(fileArray));
			setState2(prevValue => prevValue.concat(filepath));
		}
	};

	const SingleImage = (e, index) => {
		setImageError('');
		var fileObj = e.target.files;
		var fileArray = [];
		var filepath = [];

		for (let i = 0; i < fileObj.length; i++) {
			if (validateFileSize(fileObj[i])) {
				fileArray.push(URL.createObjectURL(fileObj[i]));
				filepath.push(fileObj[i]);
			} else {
				setImageError('Image size should be less than 1 MB.');
			}
		}

		// Continue with state updates only for valid files
		if (fileArray.length > 0) {
			var data = statesingle.filter(x => x != null);
			var data1 = statesingle2.filter(x => x != null);
			setStatesingle(data);
			setStatesingle2(data1);
			setStatesingle(prevValue => prevValue.concat(fileArray));
			setStatesingle2(prevValue => prevValue.concat(filepath));
		}
	};
	const indexDelete = (e, index) => {
		var filteredpreview = state1.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = state2.filter((val, i) => {
			return i !== index;
		});
		setState1(filteredpreview);
		setState2(filteredraw);
	}

	//school**

  const [schoolUpdated, setSchoolUpdated] = React.useState(location.state.data.yogaschool)
  
  const [selectedSchool, setSelectedSchool] = React.useState(location.state.data.yogaschool)

  // console.log(location.state.data.yogaschool)

	const [schoolDataUpdated, setSchoolDataUpdated] = React.useState([])

  React.useEffect(() => {
		getSchool()
   
	},[])

	const getSchool = async () => {
		try {
			
      const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getYogaSchoolListblogs`, { headers: headerlocal });
			console.log(response.data.getYogaSchoolLists,"res[ponseallll")
     
      setSchool(response.data.getYogaSchoolLists);
      
			setSchoolDataUpdated(response.data.getYogaSchoolLists)
		} catch (error) {
			console.log(error.response, "error");
		}
	}  


  console.log(school)
  console.log(schoolDataUpdated)

  // const [selectedSchool, setSelectedSchool] = React.useState(() => {
	// 	const arrVal = []
	// 	const schoolArr = location.state.data.yogaschool
	// 	if (schoolArr.length > 0) {
	// 		schoolArr.map((item) => {
	// 			arrVal.push(item.username)
	// 		})
	// 	}
	// 	return arrVal
	// });




	

  React.useEffect(() => {
		getSchoolSelected();
	}, [selectedSchool, schoolDataUpdated])

  function getSchoolSelected() {
		let array = []
    console.log(selectedSchool)




    console.log(typeof selectedSchool)

    const selectedSchools = schoolDataUpdated.filter((content) => {
      if(typeof selectedSchool === "object"){
        console.log("school")
        return selectedSchool[0]?.username === content.username
      }
      else{
        console.log("string")
        return content.username === selectedSchool 
      }
    }) ;



    selectedSchools && selectedSchools.forEach((item) => {
      const val = { username: item.username, user_id: item._id };
      array.push(val);
      });
    console.log(array)
		setSchoolUpdated(array);
    


	}

  console.log(schoolUpdated)

	

  const handlechangeSchoolSelected = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
		const {
			target: { value },
		} = event;
    console.log(value);
		setSelectedSchool(
			typeof value === 'string' ? value : value.split(','),
		);
		getSchoolSelected();
	}



	



	//Studio**

  const [studioUpdated, setStudioUpdated] = React.useState(location.state.data.yogastudio)
	const [studioDataUpdated, setStudioDataUpdated] = React.useState([])
  // const [selecttedStudio, setSelectedStudio] = React.useState(() => {
	// 	const arrVal = []
	// 	const studioArr = location.state.data.yogastudio
	// 	if (studioArr.length > 0) {
	// 		studioArr.map((item) => {
	// 			arrVal.push(item.username)
	// 		})
	// 	}
	// 	return arrVal
	// });

  const [selecttedStudio, setSelectedStudio] = React.useState(location.state.data.yogastudio)
 


	React.useEffect(() => {
		getStudio()
	}, [])

	const getStudio = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getYogaStudioListblogs`, { headers: headerlocal });
			setStudio(response.data.getYogaStudioLists);
			setStudioDataUpdated(response.data.getYogaStudioLists)
		} catch (error) {
			console.log(error.response, "error");
		}
	}



	

	React.useEffect(() => {
		getStudioSelected();
	}, [selecttedStudio, studioDataUpdated])

	function getStudioSelected() {

		var array = []
		// const selectedStudios = studioDataUpdated.filter(content => selecttedStudio === content.username) ;
		const selectedStudios = studioDataUpdated.filter((content) => {
      if(typeof selecttedStudio === "object"){
        return selecttedStudio[0]?.username === content.username
      }
      else{
        return content.username === selecttedStudio 
      }
    }) ;
    console.log(selectedStudios)
    selectedStudios && selectedStudios.map((item, i) => {
			var val = { username: item.username, user_id: item._id }
			Object.assign(item, val)
			array.push(val)
		})
    console.log(array)
		setStudioUpdated(array)
	}

	const handlechangeStudioSelected = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
    
		const {
			target: { value },
		} = event;

		setSelectedStudio(
			typeof value === 'string' ?  value : value.split(','),
		);

		getStudioSelected();

	}

	const handleToggleapprove = () => {
		setToggledapprove(!isToggledapprove)
	}

	// const SingleImage = (e, index) => {
	// 	setImageError('')
	// 	var fileObj = [];
	// 	var fileArray = [];
	// 	var filepath = [];

	// 	fileObj.push(e.target.files)
	// 	for (let i = 0; i <= fileObj[0].length; i++) {
	// 		if (i < fileObj[0].length) {
	// 			fileArray.push(URL.createObjectURL(fileObj[0][i]))
	// 			filepath.push(fileObj[0][i])
	// 		}
	// 	}
	// 	var data = statesingle.filter(x => x != null)
	// 	var data1 = statesingle2.filter(x => x != null)
	// 	setStatesingle(data)
	// 	setStatesingle2(data1)
	// 	setStatesingle(prevValue => prevValue.concat(fileArray))
	// 	setStatesingle2(prevValue => prevValue.concat(filepath))
	// }

	const indexDelete1 = (e, index) => {
		var filteredpreview = statesingle.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = statesingle2.filter((val, i) => {
			return i !== index;
		});
		setStatesingle(filteredpreview);
		setStatesingle2(filteredraw);
	}
	const handleNext = () => {
    const valid = validate();
	
		if (valid === true) {
		
			if (activeStep === 1) {
				
				const validatation = validatenew(); 
			
				if (validatation === true) {
					setActiveStep((prevActiveStep) => prevActiveStep + 1);
				}
			} else {
				
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		}
	};
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	// const handleToggle = () => {
	// 	setToggled(!isToggled)
	// }

	const handleTogglePublic = () => {
		setPublic(!isPublic)
	}

	const handleTogglePrimary = () => {
		setPrimary(!isPrimary)
	}

	const validate = () => {
		if (!blog && !categoryId && statesingle?.length == 0) {
			setError({ eventName: "This field is required." })
			setCatError("This field is required.")
			setImageError("This field is required.")

			return false
		}
		if (!blog) {
			setError({ eventName: "This field is required." })
			return false
		}
		if (!categoryId) {
			setCatError("This field is required.")
			return false
		}
		if (statesingle?.length == 0) {
			setImageError("This field is required.")
			return false
		}
		return true
	}

	const validatenew = () => {

    if (state2.length == 0 && selectedVideo.length == 0) {
        setImageAddError("This field is required.")
  setError( {video:"This field is required."})
        return false
    }

    return true
		return true
	}
	const handleUpdateBlog = () => {
		var multipleImage = state2.filter(x => x != null);
		var valid = validate()
		var validnew = validatenew()
		if (valid == true && validnew==true) {
			setTruebutton(true)
			const unlayer = emailEditorRef.current?.editor;
			unlayer?.exportHtml((data) => {
				const { design, html } = data;
				const sanitizedHtml = DOMPurify.sanitize(html);
				
				const formData = new FormData();
				// const idArray = teacherUpdated.map(id => id.user_id);
				// idArray.forEach(id => {
				// 	formData.append('createdby[]', id);
				// });
				if (teacherUpdated.length > 0) {
					const idArray = teacherUpdated.map(id => id.user_id);
					idArray.forEach(id => {
						formData.append('createdby[]', id);
					});
					formData.append('createdby[]', auth._id);
				} else {
					formData.append('createdby[]', auth._id);
				}
				// if (auth.role_id == 1) {
				// 	const idArray = teacherUpdated.map(id => id.user_id);
				// 	idArray.forEach(id => {
				// 		formData.append('createdby[]', id);
				// 	});
				// } else {
				// 	formData.append('createdby[]', auth._id);
				// }
				// formData.append("approve_blogs", true)

				formData.append("Blog_title", blog)
				formData.append("Date", value)
				formData.append("Category", categoryId)
				formData.append("Subcategory", subcategoryId)
				formData.append("shortdescription", description)
				formData.append("Description", sanitizedHtml)
				formData.append("DescriptionJSON", design && JSON.stringify(design.body))
        formData.append("instagram", user.instagram ? user.instagram : '')
        formData.append("facebook", user.facebook ? user.facebook : '' )
        formData.append("twitter", user.twitter ? user.twitter : '')
        formData.append("youtube", user.youtube ? user.youtube : '')
        formData.append("pinterest", user.pinterest ? user.pinterest : '')
        formData.append("tiktok", user.tiktok ? user.tiktok : '')
				// formData.append("publish", isToggled)
				formData.append("public", isPublic)
				formData.append("primary", isPrimary)
				formData.append('yogaschool', JSON.stringify(schoolUpdated.length > 0 ? schoolUpdated : []))
				formData.append('yogastudio', JSON.stringify(studioUpdated.length > 0 ? studioUpdated : []))
				formData.append('teacher', JSON.stringify(teacherUpdated.length > 0 ? teacherUpdated : []))
				formData.append("gallery", selectGallert === 'Image' ? 'Image' : 'Video')
				formData.append("approve_blogs", isToggledapprove)
				{ statesingle2 ? statesingle2.forEach((item) => { formData.append("newimage", item) }) : formData.append("newimage", '') }
				{
					selectGallert === 'Image' ? <>
						{multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '')}
						{selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", '') }) : formData.append("Video", '')}
						{existingimages ? existingimages.forEach((item) => { formData.append("existingimages", item) }) : formData.append("existingimages", '')}
					</>
						:
						<>
							{multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", '') }) : formData.append("additionalimages", '')}
							{selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '')}
							{existingimages ? existingimages.forEach((item) => { formData.append("existingimages", '') }) : formData.append("existingimages", '')}
						</>
				}
				formData.append("published_by", publishername)
        
				axios.put(process.env.REACT_APP_BASE_URL + `Blog/updateBlog/${location.state.data._id}`, formData, { headers: headerformdata }).then((res) => {
					setTruebutton(false)
					navigate('/blogs');
					setBlog('');
					setCategoryId('');
					setSubCategoryId('');
					setState1([])
					setState2([])
					enqueueSnackbar("Blog updated successfully", { variant: 'success' })
				}).catch((err) => {
					setTruebutton(false)
					enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
					console.log(err, "error");
				})
			})
		}
	}

	// const handleVideoChange = (e, index) => {
	// 	setImageError('')
	// 	var fileObj = [];
	// 	var fileArray = [];
	// 	var filepath = [];
	// 	fileObj.push(e.target.files)
	// 	for (let i = 0; i <= fileObj[0].length; i++) {
	// 		if (i < fileObj[0].length) {
	// 			fileArray.push(URL.createObjectURL(fileObj[0][i]))
	// 			filepath.push(fileObj[0][i])
	// 		}
	// 	}
	// 	setVideoPreviewUrl(fileArray)
	// 	setSelectedVideo(filepath)
	// }


	const handleVideoChange = (e, index) => {
		setImageError('')
		setError({ video: '' });
		var fileObj = [];
		var fileArray = [];
		var filepath = [];
		fileObj.push(e.target.files);
		for (let i = 0; i < fileObj[0].length; i++) {
			if (fileObj[0][i].size > 25 * 1024 * 1024) { // Corrected comparison
				setError({ video: 'Video should be less than 25 MB' });
				return;
			}
			fileArray.push(URL.createObjectURL(fileObj[0][i]));
			filepath.push(fileObj[0][i]);
		}

		setVideoPreviewUrl(fileArray);
		setSelectedVideo(filepath);
	}


	const clearVideo = () => {
		setSelectedVideo([]);
		setVideoPreviewUrl([]);
	};

	const handleEditGallery = (e) => {
		setSelectGallery(selectGallert === 'Image' ? 'Video' : 'Image')
	}

  const handleChange = (e, index) => {
    const { name, value } = e.target;
  
        setUser({ ...user, [name]: value });

};



	return (
    <Box className="">
      <MiniDrawer menuOrder={5} submenuOrder={15}/>
      <Box component="section" className="contentWraper">
        <Box className="whiteContainer p-20">
          <Typography component="h6" className="formTitle">
            Edit Blog
          </Typography>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && (
            <Box className="steppy_content">
              <Box className="maxw_700">
                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Blog Name
                    <Typography component="span" className="star">
                      *
                    </Typography>
                  </Typography>
                  <FormControl variant="standard" fullWidth>
                    <TextField
                      id="filled-size-small"
                      variant="outlined"
                      placeholder="Blog Name"
                      size="small"
                      className="textfield"
                      style={{ width: "100%" }}
                      value={blog}
                      onChange={(e) => {
                        setBlog(e.target.value);
                        setError({ eventName: "" });
                      }}
                    />
                    {error.eventName && (
                      <p style={{ color: "red" }} className="fz-13 m-0">
                        {error.eventName}
                      </p>
                    )}
                  </FormControl>
                </Box>

                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Box className='mb-10 d-flex align-items-start justify-content-between' >
                                        <Box className=' text-right' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography component='div' variant='body1' className='fz-14 '> Publish:</Typography>
                                            <FormControlLabel className='m-0' style={{ display: 'flex', alignItems: 'center' }} control={<IOSSwitch onClick={handleToggle} checked={isToggled} sx={{ m: 1 }} />} />
                                        </Box>
                                    </Box>
                                </Grid> */}

                <Box className="mb-20">
                  <FormControlLabel
                    className="checkbox_list"
                    control={
                      <Checkbox
                        checked={isPublic}
                        onChange={handleTogglePublic}
                        name="checkbox-value"
                        color="primary"
                      />
                    }
                    label={
                      <Typography component="div" className="label mb-0">
                        Do you want to make this event as Public?{" "}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    className="checkbox_list"
                    control={
                      <Checkbox
                        checked={isPrimary}
                        onChange={handleTogglePrimary}
                        name="checkbox-value"
                        color="primary"
                      />
                    }
                    label={
                      <Typography component="div" className="label mb-0">
                        Do you want to make this event as Primary?{" "}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    className="checkbox_list"
                    control={
                      <Checkbox
                        checked={isToggledapprove}
                        onChange={handleToggleapprove}
                        name="checkbox-value"
                        color="primary"
                      />
                    }
                    label={
                      <Typography component="div" className="label mb-0">
                        Approved?{" "}
                      </Typography>
                    }
                  />
                </Box>

                {/* {auth.role_id == 1 ? (
                  <Box className="mb-20">
                    <Typography component="div" className="label">
                      Select Teacher
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        className="select"
                        value={selectedTeacher}
                        onChange={(e) => handlechangeTeacherSelected(e)}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          const selectedTeacherss = teacherDataUpdated.filter(
                            (teacher) => selected.includes(teacher.username)
                          );
                          if (selectedTeacherss.length === 0) {
                            return "Any teacher";
                          } else if (selectedTeacherss.length === 1) {
                            return selectedTeacherss[0].username;
                          } else {
                            return `${selectedTeacherss.length} teacher selected`;
                          }
                        }}
                        MenuProps={MenuProps}
                      >
                        {teacher && teacher.length > 0 ? (
                          teacher.map((name) => (
                            <MenuItem key={name._id} value={name.username}>
                              <Checkbox
                                checked={
                                  selectedTeacher.indexOf(name.username) > -1
                                }
                              />
                              <ListItemText primary={name.username} />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No teacher found</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
					""
                )} */}

                {auth.role_id == 1 ? (
                  <Box className="mb-20">
                    <Typography component="div" className="label">
                      Select Teacher
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-single-checkbox-label"
                        id="demo-single-checkbox"
                        className="select"
                       
                        value={selectedTeacher || selectedTeacher[0]?.username }
                        onChange={(e) => handlechangeTeacherSelected(e)}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                         
                          const selectedTeacherss = teacherDataUpdated.find((teacher) => teacher.username === selected[0]?.username || teacher.username === selected);
                          
                          return selectedTeacherss
                            ? selectedTeacherss.username
                            : "Any teacher";
                        }}
                        MenuProps={MenuProps}
                      >
                        {teacher && teacher.length > 0 ? (
                          teacher.map((name) => (
                            <MenuItem key={name._id} value={name.username} disabled={name.disabled}>
                              <Checkbox
                                checked={selectedTeacher === name.username}
                              />
                              <ListItemText primary={name.username} />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No teacher found</MenuItem>
                        )}
                      </Select>
                      {error.userselect && (
                        <p className="error-field">{error.userselect}</p>
                      )}
                    </FormControl>
                  </Box>
                ) : (
                  ""
                )}
                {/* <p className='error-field'>{error.userselect}</p> */}

                {/* <Grid item xs={12} sm={12} md={6} lg={6}>
									<FormControl variant="standard">
										<Typography component='div' className='label'>Date </Typography>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DemoContainer components={['DatePicker', 'DatePicker']}>
												<DatePicker
													label="Date"
													value={value}
													onChange={(newValue) => setValue(newValue)}
													minDate={dayjs()}
												/>
											</DemoContainer>
										</LocalizationProvider>
									</FormControl>
								</Grid> */}

                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Cover Image{" "}
                    <Typography component="span" className="star">
                      *
                    </Typography>
                  </Typography>
                  <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                    {statesingle.length < 1 && (
                      <Button
                        className="multiple_upload_btn"
                        variant="contained"
                        component="label"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FileUploadOutlinedIcon className="fz-18 mr-5" />{" "}
                          Upload
                        </Box>
                        <input
                          accept={"image/png, image/jpg,image/jpeg"}
                          onChange={SingleImage}
                          hidden
                          type="file"
                        />
                      </Button>
                    )}
                  </Box>
                  {imageError && (
                    <p style={{ color: "red" }} className="fz-13 m-0">
                      {imageError}
                    </p>
                  )}
                </Box>
                <Box>
                  {statesingle.length > 0 && (
                    <Grid
                      container
                      columnSpacing={1}
                      rowSpacing={1}
                      className="multiple_upload_container mb-10"
                    >
                      {statesingle.length > 0
                        ? statesingle.map((x, i) => (
                            <Grid item xs={6} sm={4} md={4} lg={4}>
                              <Box className="uploaded_img">
                                <Button
                                  className="close_icon"
                                  onClick={(e) => indexDelete1(e, i)}
                                >
                                  <Close />
                                </Button>
                                <img
                                  src={
                                    x.slice(0, 4) === "blob"
                                      ? x
                                      : process.env.REACT_APP_BASE_URL + `${x}`
                                  }
                                  alt=""
                                  height={155}
                                  width={195}
                                />
                              </Box>
                            </Grid>
                          ))
                        : ""}
                    </Grid>
                  )}
                </Box>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    className=" fz-12 mt-10 mb-20"
                    component="p"
                    variant="p"
                  >
                    <strong>Note:</strong> Image size should be more than 500px
                    * 600px for better quality picture.
                  </Typography>
                </Grid>

                {/* {auth.role_id == 1 ? (
                  <Box className="mb-20">
                    <Typography component="div" className="label">
                      Select School <Typography component="span"></Typography>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        className="select"
                        value={selectedSchool}
                        onChange={(e) => handlechangeSchoolSelected(e)}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          const selectedSchoolsesss = schoolDataUpdated.filter(
                            (school) => selected.includes(school.username)
                          );
                          if (selectedSchoolsesss.length === 0) {
                            return "Any school";
                          } else if (selectedSchoolsesss.length === 1) {
                            return selectedSchoolsesss[0].username;
                          } else {
                            return `${selectedSchoolsesss.length} school selected`;
                          }
                        }}
                        MenuProps={MenuProps}
                      >
                        {school && school.length > 0 ? (
                          school.map((name) => (
                            <MenuItem key={name._id} value={name.username}>
                              <Checkbox
                                checked={
                                  selectedSchool.indexOf(name.username) > -1
                                }
                              />
                              <ListItemText primary={name.username} />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No school found</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
					""
                )} */}

                {auth.role_id == 1 ? (
                  <Box className="mb-20">
                    <Typography component="div" className="label">
                      Select School <Typography component="span"></Typography>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-single-checkbox-label"
                        id="demo-single-checkbox"
                        className="select"
                        value={selectedSchool || selectedSchool[0]?.username}
                        onChange={(e) => handlechangeSchoolSelected(e)}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          // const selectedSchoolsesss = schoolDataUpdated.find(
                          //   (school) => school.username === selected
                          // );
                          console.log(selected)

                          const selectedSchoolsesss = schoolDataUpdated.find((school) => school.username === selected[0]?.username || school.username === selected);

                          return selectedSchoolsesss
                            ? selectedSchoolsesss.username
                            : "Any school";
                        }}
                        MenuProps={MenuProps}
                      >
                        {school && school.length > 0 ? (
                          school.map((name) => (
                            <MenuItem key={name._id} value={name.username} disabled={name.disabled}>
                              <Checkbox
                                checked={selectedSchool === name.username}
                              />
                              <ListItemText primary={name.username} />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No school found</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  ""
                )}

                {/* {/ <p className='error-field'>{error.userselect}</p> /} */}

                {/* {auth.role_id == 1 ? <Box className="mb-20">
									<Typography component='div' className='label'>Select Studio <Typography component='span'></Typography></Typography>
									<FormControl fullWidth>
										<Select
											labelId="demo-multiple-checkbox-label"
											id="demo-multiple-checkbox"
											multiple
											className='select'
											value={selecttedStudio}
											onChange={(e) => handlechangeStudioSelected(e)}
											input={<OutlinedInput />}
											renderValue={(selected) => {
												const selectedStudioesss = studioDataUpdated.filter(studio => selected.includes(studio.username));
												if (selectedStudioesss.length === 0) {
													return "Any studio";
												} else if (selectedStudioesss.length === 1) {
													return selectedStudioesss[0].username;
												} else {
													return `${selectedStudioesss.length} studio selected`;
												}
											}}
											MenuProps={MenuProps}
										>
											{studio && studio.length > 0 ? studio.map((name) => (
												<MenuItem key={name._id} value={name.username} >
													<Checkbox checked={selecttedStudio.indexOf(name.username) > -1} />
													<ListItemText primary={name.username} />
												</MenuItem>
											)) : <MenuItem>No studio found</MenuItem>}
										</Select>
									</FormControl>
								</Box> : ''} */}

                {auth.role_id == 1 ? (
                  <Box className="mb-20">
                    <Typography component="div" className="label">
                      Select Studio <Typography component="span"></Typography>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-single-checkbox-label"
                        id="demo-single-checkbox"
                        className="select"
                        value={ selecttedStudio || selecttedStudio[0]?.username}
                        onChange={(e) => handlechangeStudioSelected(e)}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          
                          const selectedStudioesss = studioDataUpdated.find((studio) => studio.username === selected[0]?.username || studio.username === selected);

                          return selectedStudioesss
                            ? selectedStudioesss.username
                            : "Any studio";
                        }}
                        MenuProps={MenuProps}
                      >
                        {studio && studio.length > 0 ? (
                          studio.map((name) => (
                            <MenuItem key={name._id} value={name.username} disabled={name.disabled}>
                              <Checkbox
                                checked={selecttedStudio === name.username}
                              />
                              <ListItemText primary={name.username} />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No studio found</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  ""
                )}
                {/* {/ <p className='error-field'>{error.userselect}</p> /} */}

                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Select Category{" "}
                    <Typography component="span" className="star">
                      *
                    </Typography>
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      className="select"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={categoryId ? categoryId : "sel"}
                      onChange={(e) => {
                        setCategoryId(e.target.value);
                      }}
                    >
                      <MenuItem disabled value={"sel"}>
                        Select category
                      </MenuItem>
                      {recordings && recordings?.length > 0 ? (
                        recordings.map((data) => (
                          <MenuItem value={data._id}>
                            {data.category_title}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>{"No Record's found"}</MenuItem>
                      )}
                    </Select>
                    {catError && (
                      <p style={{ color: "red" }} className="fz-13 m-0">
                        {catError}
                      </p>
                    )}
                  </FormControl>
                </Box>
                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Select Sub Category
                  </Typography>
                  <Box className="flex-center">
                    <FormControl fullWidth style={{ flex: 1 }}>
                      <Select
                        className="select"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        disabled={categoryId !== "" ? false : true}
                        value={subcategoryId ? subcategoryId : "sel18"}
                        onChange={(e) => {
                          setSubCategoryId(e.target.value);
                          setSubCatError("");
                          setError({ servicename: "" });
                          setCatError("");
                          setSubCatError("");
                        }}
                      >
                        <MenuItem disabled value={"sel18"}>
                          Select sub category
                        </MenuItem>
                        {data && data?.length > 0 ? (
                          data.map((data5) => (
                            <MenuItem value={data5._id}>
                              {data5.subcategory_title}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>{"No Record's found"}</MenuItem>
                        )}
                      </Select>
                      {subcatError && (
                        <p style={{ color: "red" }} className="fz-13 m-0">
                          {subcatError}
                        </p>
                      )}
                    </FormControl>
                    {subcategoryId !== "" && (
                      <RestartAltIcon
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                        onClick={() => handleRemoveRestart()}
                      />
                    )}
                  </Box>
                </Box>

                  {/* <Box className="mb-20">
                                  <FormControl variant="standard" fullWidth>
                                    <Typography component="div" className="label">
                                      Instagram Link:
                                      <Typography
                                        component="span"
                                        className="star"
                                      ></Typography>
                                    </Typography>
                                    <TextField
                                      id="filled-size-small"
                                      variant="outlined"
                                      size="small"
                                      name="instagram"
                                      className="textfield"
                                      value={user.instagram}
                                      placeholder="Instagram Link"
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                </Box>
                                <Box className="mb-20">
                                  <FormControl variant="standard" fullWidth>
                                    <Typography component="div" className="label">
                                      Facebook Link:
                                      <Typography
                                        component="span"
                                        className="star"
                                      ></Typography>
                                    </Typography>
                                    <TextField
                                      id="filled-size-small"
                                      variant="outlined"
                                      size="small"
                                      name="facebook"
                                      className="textfield"
                                      value={user.facebook}
                                      placeholder="Facebook Link"
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                </Box>
                                <Box className="mb-20">
                                  <FormControl variant="standard" fullWidth>
                                    <Typography component="div" className="label">
                                      Twitter Link:
                                      <Typography
                                        component="span"
                                        className="star"
                                      ></Typography>
                                    </Typography>
                                    <TextField
                                      id="filled-size-small"
                                      variant="outlined"
                                      size="small"
                                      name="twitter"
                                      className="textfield"
                                      value={user.twitter}
                                      placeholder="Twitter Link"
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                </Box>
                                <Box className="mb-20">
                                  <FormControl variant="standard" fullWidth>
                                    <Typography component="div" className="label">
                                      Youtube Link:
                                      <Typography
                                        component="span"
                                        className="star"
                                      ></Typography>
                                    </Typography>
                                    <TextField
                                      id="filled-size-small"
                                      variant="outlined"
                                      size="small"
                                      name="youtube"
                                      className="textfield"
                                      value={user.youtube}
                                      placeholder="Youtube Link"
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                </Box>
                                <Box className="mb-20">
                                  <FormControl variant="standard" fullWidth>
                                    <Typography component="div" className="label">
                                      Pinterest Link:
                                      <Typography
                                        component="span"
                                        className="star"
                                      ></Typography>
                                    </Typography>
                                    <TextField
                                      id="filled-size-small"
                                      variant="outlined"
                                      size="small"
                                      name="pinterest"
                                      className="textfield"
                                      value={user.pinterest}
                                      placeholder="Pinterest Link"
                                      onChange={handleChange}
                                    />
                                  </FormControl>
                                </Box>
                        <Box className="mb-20">
                        <FormControl variant="standard" fullWidth>
                                                            <Typography component='div' className='label'>Tiktok Link:<Typography component='span' className='star'></Typography></Typography>
                                                            <TextField
                                                                id="filled-size-small"
                                                                variant="outlined"
                                                                size="small"
                                                                name='tiktok'
                                                                className='textfield'
                                                                value={user.tiktok}
                                                                placeholder='Tiktok Link'
                                                                onChange={handleChange}
                                                            />
                
                                                        </FormControl>
                                </Box> */}
                
                <Box className="mb-20">
                  <Typography component="div" className="label">
                    Short Description{" "}
                  </Typography>
                  <FormControl variant="standard" fullWidth>
                    <textarea
                      placeholder="Short Description "
                      className="textarea"
                      defaultValue={description}
                      onChange={(e) => setDescription(e.target.value)}
                      rows={4}
                      cols={40}
                      style={{ resize: "none" }}
                    />
                  </FormControl>
                </Box>
                {activeStep === 0 && (
                  <>
                    <Box
                      className="heading-btns mt-40"
                      style={{ textAlign: "end" }}
                    >
                      <Button
                        onClick={handleNext}
                        variant="contained"
                        className="theme-btn  "
                      >
                        Next
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          )}
          {activeStep === 1 && (
            <Box className="steppy_content mb-10">
              <Grid
                container
                columnSpacing={2}
                rowSpacing={2}
                className=" mb-10"
              >
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Typography component="div" className="label">
                    Images/Video{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8}>
                  <Box className=" ">
                    <RadioGroup
                      row
                      aria-labelledby="demo-customized-radios"
                      name="payment_type"
                      onChange={handleEditGallery}
                      value={selectGallert}
                    >
                      <FormControlLabel
                        value="Image"
                        className="radio-btns"
                        control={<BpRadio />}
                        label={
                          <Typography className="fz-14">
                            Additional images
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="Video"
                        className="radio-btns"
                        control={<BpRadio />}
                        label={<Typography className="fz-14">Video</Typography>}
                      />
                    </RadioGroup>
                  </Box>
                </Grid>
                {selectGallert === "Image" ? (
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box className=" ">
                        <Typography component="div" className="label">
                          Additional Images
                          <Typography component="span" className="star">
                                                      {" "}
                                                      *{" "}
                                                    </Typography>
                        </Typography>

                        {state1.length < 1 && (
                          <Button
                            className="multiple_upload_btn"
                            variant="contained"
                            component="label"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <FileUploadOutlinedIcon className="fz-18 mr-5" />{" "}
                              Upload
                            </Box>
                            <input
                              accept={"image/png, image/jpg,image/jpeg"}
                              onChange={multipleImgChangeMultiple}
                              hidden
                              multiple
                              type="file"
                            />
                          </Button>
                        )}
                      </Box>
                    </Grid>
                    {imageAddError && (
                      <p style={{ color: "red" }} className="fz-13 m-0 ml-20">
                        {imageAddError}
                      </p>
                    )}
                    <Grid item xs={12} sm={12} md={12}>
                      {state1.length > 0 && (
                        <Grid
                          container
                          columnSpacing={1}
                          rowSpacing={1}
                          className="multiple_upload_container"
                        >
                          {state1.length > 0
                            ? state1.map((x, i) => (
                                <Grid item xs={6} sm={4} md={3} lg={3}>
                                  <Box className="uploaded_img">
                                    <Button
                                      className="close_icon"
                                      onClick={(e) => indexDelete(e, i)}
                                    >
                                      <Close />
                                    </Button>
                                    <img
                                      src={
                                        x.slice(0, 4) === "blob"
                                          ? x
                                          : process.env.REACT_APP_BASE_URL +
                                            `${x}`
                                      }
                                      alt=""
                                      height={155}
                                      width={195}
                                    />
                                  </Box>
                                </Grid>
                              ))
                            : ""}
                          <Grid item xs={6} sm={4} md={3} lg={3}>
                            <label
                              htmlFor="contained-button-file1"
                              className="w-full"
                            >
                              <Box className="multiple_upload_btn">
                                <Input
                                  inputProps={{
                                    accept: "image/png, image/jpg,image/jpeg",
                                  }}
                                  onChange={multipleImgChangeMultiple}
                                  style={{ display: "none" }}
                                  id="contained-button-file1"
                                  type="file"
                                />
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <FileUploadOutlinedIcon />
                                </Box>
                              </Box>
                            </label>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography
                        className=" fz-12 mt-10 mb-20"
                        component="p"
                        variant="p"
                      >
                        <strong>Note:</strong> Image size should be more than
                        280px * 280px for better quality picture.
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography component="div" className="label">
                        Video
                        <Typography component="span" className="star">
                                                    {" "}
                                                    *{" "}
                                                  </Typography>
                      </Typography>

                      <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                        {videoPreviewUrl && videoPreviewUrl?.length === 0 && (
                          <Button
                            className="multiple_upload_btn"
                            variant="contained"
                            component="label"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <FileUploadOutlinedIcon className="fz-18 mr-5" />{" "}
                              Upload
                            </Box>
                            <input
                              id="video-upload"
                              accept="video/*"
                              onChange={handleVideoChange}
                              hidden
                              type="file"
                            />
                          </Button>
                        )}
                        {videoPreviewUrl && videoPreviewUrl?.length > 0 && (
                          <Grid
                            container
                            columnSpacing={1}
                            rowSpacing={1}
                            className="multiple_upload_container mt-20"
                          >
                            {videoPreviewUrl.length > 0
                              ? videoPreviewUrl.map((x, i) => (
                                  <Grid item xs={6} sm={4} md={4} lg={4}>
                                    <Box className="uploaded_vdo ">
                                      <Button
                                        className="close_icon"
                                        onClick={(e) => clearVideo(e, i)}
                                      >
                                        <Close />
                                      </Button>
                                      <video className="uploaded_vdo" controls>
                                        <source
                                          src={
                                            x &&
                                            x.slice &&
                                            x.slice(0, 4) === "blob"
                                              ? x
                                              : process.env.REACT_APP_BASE_URL +
                                                `${x}`
                                          }
                                          height={155}
                                          width={195}
                                        />
                                      </video>
                                    </Box>
                                  </Grid>
                                ))
                              : ""}
                          </Grid>
                        )}
                      </Box>
                      {error.video && (
                        <p style={{ color: "red" }} className="fz-13 m-0">
                          {error.video}
                        </p>
                      )}
                    </Grid>
                  </>
                )}
                {/* <Grid item xs={12} sm={12} md={12} lg={12} className='mb-10'>
									<Typography component='div' className='label'>Description </Typography>
									<div className="editorclass">
										<Editor
											editorState={editorState}
											onEditorStateChange={handleEditorChange}
											toolbarClassName="toolbar"
											wrapperClassName="wrapperClassName"
											editorClassName="editorcontent"
											toolbar={{
												options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image'],
												inline: {
													options: ['bold', 'italic', 'underline'],
												},
												blockType: {
													options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
												},
												list: {
													options: ['unordered', 'ordered'],
												},
												textAlign: {
													options: ['left', 'center', 'right', 'justify'],
												},
												link: {
													options: ['link'],
												},
												emoji: {
													options: [
														'😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇',
														'😍', '😋', '😎', '😴', '🤔', '😳', '🤗', '🤐', '😷', '🤒'
													],
												},
												image: {
													uploadEnabled: true,
													previewImage: true,
													inputAccept: 'image/*',
													alt: { present: false, mandatory: false },
												},
											}}
										/>
									</div>
								</Grid> */}
                {activeStep === 1 && (
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      className="heading-btns mt-40"
                      style={{ textAlign: "end" }}
                    >
                      <Button
                        onClick={handleBack}
                        variant="contained"
                        className="theme-btn  mr-10"
                      >
                        Back
                      </Button>

                      <Button
                        onClick={handleNext}
                        variant="contained"
                        className="theme-btn  "
                      >
                        Next
                      </Button>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
          {activeStep === 2 && (
            <Box className="steppy_content mb-10">
              <Grid item xs={12} sm={12} md={12} lg={12} className="mb-10">
                <EmailEditor
                  ref={emailEditorRef}
                  onLoad={onLoad}
                  projectId={144031}
                  tools={{
                    "custom#product_tool": {
                      // data: { products },
                      // properties: {
                      // 	productLibrary: {
                      // 		editor: {
                      // 			data: {
                      // 				products,
                      // 			},
                      // 		},
                      // 	},
                      // },
                    },
                  }}
                  options={{
                    designTags: {
                      business_name: "My business",
                      current_user_name: "Ron",
                    },
                    customCSS: [
                      "https://examples.unlayer.com/examples/product-library-tool/productTool.css",
                    ],
                    customJS: [
                      window.location.protocol +
                        "//" +
                        window.location.host +
                        "/custom.js",
                    ],
                  }}
                />
              </Grid>
              <Box className="heading-btns mt-20" style={{ textalign: "end" }}>
                <Button
                  onClick={handleEditor}
                  variant="contained"
                  className="theme-btn  mr-10"
                >
                  SaveDesign
                </Button>
              </Box>
            </Box>
          )}

          <>
            {activeStep === 2 && truebutton === false ? (
              <>
                <Box
                  className="heading-btns mt-20"
                  style={{ textAlign: "end" }}
                >
                  <Button
                    onClick={handleBack}
                    variant="contained"
                    className="theme-btn  mr-10"
                  >
                    Back
                  </Button>
                  <Button
                    onClick={handleUpdateBlog}
                    variant="contained"
                    className="theme-btn"
                  >
                    Update
                  </Button>
                </Box>
              </>
            ) : (
              activeStep === 2 &&
              truebutton === true && (
                <>
                  <Box
                    className="heading-btns mt-20"
                    style={{ textAlign: "end" }}
                  >
                    <Button
                      onClick={handleBack}
                      variant="contained"
                      className="theme-btn  mr-10"
                    >
                      Back
                    </Button>
                    <Button variant="contained" className="theme-btn  mr-10">
                      Please wait...
                    </Button>
                  </Box>
                </>
              )
            )}
          </>
        </Box>
      </Box>
    </Box>
  );
}
