import React, { useState } from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Tooltip, Grid, InputAdornment, Avatar, Input, TextField, FormControlLabel, RadioGroup, Checkbox, Radio, Typography, IconButton, TextareaAutosize } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SearchOutlined, AddAPhoto } from '@mui/icons-material';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom'
import Avatarimage from "../../../assets/images/Avatar.png"
import { locale } from 'moment';
import Switch from '@mui/material/Switch';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';

import { AiOutlineInfoCircle } from 'react-icons/ai';
import { usePlacesWidget } from "react-google-autocomplete";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;



const AddUserName = () => {

    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }

    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem("userData"))
    let location = useLocation()
    var data = location.state ? location.state.result : null
    const [user, setUser] = React.useState({  username: ''});
    const [error, setError] = React.useState({ firstname: "", username: "", email: "", subscription: "" })
    const [isToggled, setToggled] = React.useState(false)
    const [isActive, setActive] = React.useState(false)
    const [plans, setPlans] = React.useState([])
    const [cityName, setCityName] = React.useState('')
    const [selectedPlan, setSelectedPlan] = useState('')
    const [userId, setUserId] = useState('')
    const [isChecked, setChecked] = React.useState(false)
    const [isPrimary, setPrimary] = React.useState(false)
    const [customerid, setCustomerId] = useState('')
    const [lat, setLat] = React.useState('')
    const [lng, setLng] = React.useState('')
    const [truebutton, setTruebutton] = React.useState(false)
    const [plan, setPlan] = useState('')
    const [users, setUsers] = useState('')
    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };



    const handleChange = (e, index) => {
        const { name, value } = e.target;
        // let isValid = true;
        let errorMessage = '';
        console.log(value)

        // Validate Username
        if (name === 'username') {
            const trimmedValue = value.trim(); // Trim leading and trailing spaces
            console.log(trimmedValue)
            if (trimmedValue === '' || !/^[a-zA-Z0-9]+$/.test(trimmedValue)) {
                // isValid = false;
                errorMessage = "Username should only contain alphanumeric characters (no spaces or special characters)";
            }
            setUser({ ...user, [name]: value });

        }

        // Validate Name
        // if (name === 'firstname') {
        //     if (!value || /^\d+$/.test(value) || /^[^a-zA-Z0-9]+$/.test(value)) {
        //         isValid = false;
        //         errorMessage = "Name should not contain only numbers or special characters";
        //     }
        // }

        // Validate Email
        // if (name === 'email') {
        //     if (!value || /^\d+$/.test(value) || /^[^a-zA-Z0-9]+$/.test(value)) {
        //         isValid = false;
        //         errorMessage = "Please enter a valid Email";
        //     } else {
        //         const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
        //         if (!emailPattern.test(value)) {
        //             isValid = false;
        //             errorMessage = "Please enter a valid Email";
        //         }
        //     }
        // }

        // Validate Specialization
        // if (name === 'specialization') {
        //     if (!value || /^\d+$/.test(value) || /^[^a-zA-Z0-9]+$/.test(value)) {
        //         isValid = false;
        //         errorMessage = "Specialization should not contain only numbers or special characters";
        //     }
        // }

        // Update state based on validation result
        // if (name === 'specialization') {
        //     const updatedSpecializations = [...user.specialization];
        //     updatedSpecializations[index] = value;
        //     setUser({ ...user, specialization: updatedSpecializations });
        // } else {
        //     setUser({ ...user, [name]: value });
        // }

        // Update error state
        setError({ ...error, [name]: errorMessage });
    };

    const handleAddSpecialization = () => {
        setUser({ ...user, specialization: [...user.specialization, ''] });
    };

    React.useEffect(() => {
		getUser()
	}, [])

    const getUser = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `Userrole/getUserroles`, { headers: headerlocal }).then((res) => {
			console.log(res,"users res");
            setUsers(res.data.userroles)
		}).catch((error) => {
			console.log(error.response, "error");
		})
	}

    const handleRemoveSpecialization = (index) => {
        const updatedSpecializations = [...user.specialization];
        updatedSpecializations.splice(index, 1);
        setUser({ ...user, specialization: updatedSpecializations });
    };

    // const handleaddphoto = (e) => {
    //     setUser({ ...user, Imageprew: URL.createObjectURL(e.target.files[0]), Imageraw: e.target.files[0] })
    //     setError('')
    // }

    const handleToggle = () => {
        setToggled(!isToggled)
    }

    const handleToggleActive = () => {
        setActive(!isActive)
    }

    const handleaddphoto = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 1) {
            setError({ image: 'Image should be less than 1 MB' });
            setUser({
                ...user,
                Imageprew: '',
                Imageraw: null,
            });
        } else {
            setUser({
                ...user,
                Imageprew: URL.createObjectURL(file),
                Imageraw: file,
            });
            setError({ image: '' });
        }
    };

    const handleChecked = () => {
        setChecked(!isChecked)
    }
    // console.log(!isChecked, "isCheckedisChecked");

    const handleTogglePrimary = () => {
        setPrimary(!isPrimary)
    }

   

    // let urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
    const validation = () => {
        let isValid = true;
        const errors = {};

        const trimmedValue = user.username.trim();
        console.log(user.username)
        if(!user.username)
        {
            errors.username = "This feild is required";
            isValid = false
            setError(errors);
            return isValid;

        }
     else if ( !/^[A-Za-z0-9]+$/.test(trimmedValue)||/^[0-9]+$/.test(trimmedValue) ) {
            errors.username = "Username should only contain alphanumeric characters (no spaces or special characters)";
            isValid = false;
        }

        // if (!user.firstname || /^\d+$/.test(user.firstname) || /^[^a-zA-Z0-9]+$/.test(user.firstname)) {
        //     errors.firstname = "Name can't be empty or contain only numbers or special characters";
        //     isValid = false;
        // }

        // if (!user.email) {
        //     errors.email = "Email cannot be empty";
        //     isValid = false;
        // } else {
        //     const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
        //     if (!emailPattern.test(user.email)) {
        //         errors.email = "Please enter a valid Email";
        //         isValid = false;
        //     }
        // }

        setError(errors);
        return isValid;
    };


    const subscriptionDetails = (q, cid, subscribeId) => {
        console.log(subscribeId, "-------");
        let body = {
            planId: selectedPlan.stripePlanId,
            userId: q,
            customerId: cid,
            subscriptionId: subscribeId

        };
        console.log(body, "susctijd");
        axios.post(process.env.REACT_APP_BASE_URL + `subscription/create-session`, body, headerlocal)
            .then((res) => {
                console.log(res.data, "SESSION");
            })
            .catch((err) => {

                console.log(err, "session error");
            });
    };


    // console.log(user.username, "1");
    // console.log(user.firstname, "2");

    const addUserDetails = () => {
        const validator = validation();
        if (validator === true) {
            setTruebutton(true)
            // const formData = new FormData();
            // formData.append("usertype", 'Member')
            // formData.append("image", user.Imageraw ? user.Imageraw : "");
            // formData.append("name", user.firstname);
            // formData.append("username", user.username);
            // formData.append("email", user.email);
            // formData.append("specialization", user.specialization);
            // const transformedSpecializations = user.specialization.flatMap(spec => spec.split(',').map(s => s.trim())).filter(Boolean);
            // transformedSpecializations.forEach((spec, index) => {
            //     formData.append(`specialization[${index}]`, spec);
            // });
            // formData.append("userrole", userId);
            // formData.append("address", cityName);
            // formData.append("description", user.description);
            // formData.append("contact_number", user.phone);
            // formData.append("verified",isToggled);
            // formData.append("active",isActive);
            // formData.append("gender", user.gender ? user.gender : '');
            // formData.append("role_id", 3);
            // formData.append("blocked", true);
            // console.log(...formData, "---------");

            const body = {
                username: user.username,
            }
            console.log(body,"boddddddddddddddddddddddddyyyyyyyy")
            axios.post(process.env.REACT_APP_BASE_URL + `MyUserIds/AddMyUserid`, body)
                .then((res) => {
                    setTruebutton(false)
                    console.log(res, "ressss---");
                    // setUser({ firstname: '', email: '', SetVerifyTeacher: "", gender: 'Male', phone: '', Imageprew: "", Imageraw: "", specialization: "", description: "" });
                    // setSelectedPlan('');
                    navigate(`/username`);
                    enqueueSnackbar("User added successfully", { variant: 'success' });
                })
                .catch((err) => {
                    setTruebutton(false)
                    console.log(err.response, "------");
                    enqueueSnackbar(err.response.data.error ? err.response.data.error : err.response.data ? err.response.data : "Error occurred", { variant: 'error' });
                });
        }
    };


    React.useEffect(() => {
        getPlans()
    }, [])

    const getPlans = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `Subscription/getSubscriptionPlansYogaTeacher`, { headers: headerlocal }).then((res) => {
            console.log(res.data, "getplanssssssssssssssssssss");
            setPlans(res.data.getSubscriptiondata)
        }).catch((error) => {
            console.log(error, "Error ");
        })
    }



  return (
    <>
     <Box className='Addcompany'>
            <MiniDrawer menuOrder={22} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Add User</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    
                                   

                              
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Username: <Typography component='span' className='star'>*</Typography>
                                                <Tooltip title="This name will be used to create a unique URL for User">
                                                    <IconButton aria-label="information">
                                                        <AiOutlineInfoCircle size={20} />
                                                    </IconButton>
                                                </Tooltip></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='username'
                                                className='textfield'
                                                placeholder='Username'
                                                value={user.username}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </FormControl>
                                        {error.username && <div className="error-message" style={{ color: "red", fontSize: 12 }}>{error.username}</div>}
                                    </Grid>

                                  
                                </Grid>

                                {truebutton === false ?
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10' onClick={() => addUserDetails()}>Save</Button>
                                    </Box>
                                    : truebutton === true &&
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10'>Please Wait</Button>
                                    </Box>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    </>
  )
}

export default AddUserName