import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, Radio } from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';

export default function EditCategory() {
   

    function BpRadio(props) {
        return (
            <Radio
                sx={{
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                }}
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        );
    }
    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#f3714d',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    });

//UseState's:
    let location = useLocation()
    const tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem("userData"))
    const [truebutton, setTruebutton] = React.useState(false)
    var editId = location.state.data._id
    const [tag, setTag] = React.useState({ name: location.state.data.tagname, description: location.state.data.description })
    const [error, setError] = React.useState({ name: "", description: "" })


//Onchange:
    let name, value;
    const handleTag = (e) => {
        const { name, value } = e.target;
        // Regular expression to allow only letters and spaces
        const hasAlphanumeric = /[a-zA-Z]/;

        if (name === 'name') {
            if (!hasAlphanumeric.test(value)) {
                setError({ ...error, name: "Tag name must contain at least one letter." });
            } else {
                setError({ ...error, name: "" });
            }
        }

        setTag({ ...tag, [name]: value });
    }


//Validation:
    const Validator = () => {
        const hasAlphanumeric = /[a-zA-Z]/;

        if (!tag.name) {
            setError({ name: "This field is required." })
            return false
        }

        if (!hasAlphanumeric.test(tag.name)) {
            setError({ ...error, name: "Tag name must contain at least one letter." });
            return false;
        }
        return true
    }


//To Update Tag:
    const handleUpdate = async () => {
        var Valid = Validator()
        if (Valid) {
            setTruebutton(true)
            let body = {
                tagname: tag.name,
                description: tag.description,
            }
            await axios.put(process.env.REACT_APP_BASE_URL + `tags/updateTags/${editId}`, body, tokendata)
                .then((res) => {
                    setTruebutton(false);
                    navigate('/tag')
                    enqueueSnackbar("Tag updated successfully", { variant: 'success' })
                })
                .catch((error) => {
                    setTruebutton(false);
                    enqueueSnackbar(error.response.data.error?error.response.data.error:"Something went wrong.", { variant: 'error' })
                    console.log(error.response, "error");
                })
        }
    }


    

    return (
        <Box className=''>
            <MiniDrawer menuOrder={1} submenuOrder={3} />
            <Box component="section" className="contentWraper">

                <Grid container rowSpacing={5} columnSpacing={3}>

                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className='whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle' >Edit Tag</Typography><br></br>
                                <Grid container rowSpacing={2} columnSpacing={2}>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Tag Name <Typography component='span' className='star'>*</Typography></Typography>

                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Name'
                                                name="name"
                                                value={tag.name}
                                                onChange={(e) => { handleTag(e); setError({ name: "" }) }}

                                            />
                                            {error.name && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.name}</p>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Description</Typography>
                                            <textarea
                                                className='textarea'
                                                placeholder='Description'
                                                name="description"
                                                value={tag.description}
                                                onChange={(e) => handleTag(e)}
                                                rows={4}
                                                cols={40}
                                                style={{ resize: 'none' }}  />

                                        </FormControl>
                                    </Grid>

                                </Grid>

                                {truebutton === false ?
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button onClick={handleUpdate} variant="contained" className='theme-btn'>Update</Button>
                                    </Box> : truebutton === true &&
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='theme-btn'>Please Wait</Button>
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}

