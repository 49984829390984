import React, { useEffect, useState } from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid, ResponsiveContainer,
	Tooltip as RechartsTooltip,
} from 'recharts';
import { FormControl, Select, MenuItem, Box, Typography } from '@mui/material';
import axios from 'axios';

const SiteVisitorChart = () => {
	const [timePeriod, setTimePeriod] = useState(3);
	const [chartData, setChartData] = useState([]);
	const [fullData, setFullData] = useState([]);

	const fetchData = () => {
		axios
			.get(`${process.env.REACT_APP_BASE_URL}Admin/UserVisit?monthsBack=${timePeriod}`)
			.then((res) => {


				setFullData(res.data.monthlyVisits);
				setChartData(res.data.monthlyVisits.slice(0, timePeriod));
			})
			.catch((err) => {
				console.log("Error fetching data:", err);
				setFullData([]);
				setChartData([]);
			});
	};

	// Use effect to fetch data initially and when the timePeriod changes
	useEffect(() => {
		fetchData();
	}, [timePeriod]);

	const handleSelectChange = (event) => {
		const selectedTimePeriod = event.target.value;
		setTimePeriod(selectedTimePeriod);
		setChartData(fullData.slice(0, selectedTimePeriod));
	};

	return (
    <Box>
      <Box className="flex-center flex-between flex-wrap mb-20">
        <Typography variant="h6" component="h6" className="fz-18 fw-600 ">
          Site Visitors
        </Typography>
        <FormControl sx={{ width: "200px" }}>
          <Select
            value={timePeriod}
            onChange={handleSelectChange}
            className="select"
          >
            <MenuItem value= {3} sx={{ fontSize: "14px", color: "black" }}>
              Last 3 Months
            </MenuItem>
            <MenuItem value={6} sx={{ fontSize: "14px", color: "black" }}>
              Last 6 Months
            </MenuItem>
            <MenuItem value={9} sx={{ fontSize: "14px", color: "black" }}>
              Last 9 Months
            </MenuItem>
            <MenuItem value={12} sx={{ fontSize: "14px", color: "black" }}>
              Last 12 Months
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box className="position_rel flex-center justify-content-center mt-20 p-20 border borderR_4">
        <BarChart width={600} height={350} data={chartData}>
          <XAxis dataKey="month" tickLine={false} />
          <YAxis tickLine={false} />
          <CartesianGrid strokeDasharray="3" vertical={false} />
          <RechartsTooltip />
          <Bar dataKey="count" fill="#ff6c47" barSize={20} label={false} />
        </BarChart>
        <Typography
          variant="body1"
          component="p"
          sx={{
            position: "absolute",
            left: "20px",
            top: "20%",
            transform: "translateY(100%) rotate(180deg)", // Added rotation here
            writingMode: "vertical-rl",
            color: "#000",
            zIndex: 8,
            letterSpacing: "0.5px",
			fontWeight: "bold"
          }}
          className="fz-14 fw-600"
        >
          Visitors Count
        </Typography>
        <Typography
          className="fz-14 fw-600"
          variant="h6"
          component="h6"
          sx={{
            position: "absolute",
            left: "50%",
            bottom: "20px",
            color: "#000",
            zIndex: 8,
            letterSpacing: "0.5px",
			bottom: "10px",

          }}
        >
          Month
        </Typography>
      </Box>
    </Box>
  );
};

export default SiteVisitorChart;