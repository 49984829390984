import * as React from 'react';
import Box from '@mui/material/Box';
import { TableBody, Typography, Grid, Paper, Tooltip, Button, TextField, Select, InputAdornment, FormControl, FormControlLabel, Card, Input, Divider, CircularProgress, Menu } from '@mui/material';
import { PlaylistAddRounded, CloseOutlined, InfoOutlined, DataArraySharp, EditOutlined, Close, Add, SearchOutlined, GridView, ListAlt, CheckCircle, Verified, FilterAlt } from '@mui/icons-material';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DOMPurify from 'dompurify';
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import { useSnackbar } from 'notistack'
import Switch, { SwitchProps } from '@mui/material/Switch';
import CardContent from '@mui/material/CardContent';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import moment from 'moment';
import Courseimage from '../../../assets/images/courseimage.png'





const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};




export default function Datatable() {
    const params = useParams()
    const auth = JSON.parse(localStorage.getItem('userData'))
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [newdata, setNewdata] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [openview, setOpenview] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(8);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [view, setView] = React.useState(false);
    const menuopen = Boolean(anchorEl);
    const [alldata, setAlldata] = React.useState('');
    const [cat, setCat] = React.useState([]);
    const [deleteid, setDeleteId] = React.useState('');
    const [catid, setCatId] = React.useState('all');
    const [status, setStatus] = React.useState('all')
    const [dense, setDense] = React.useState(true);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    //Course:
    const [courseinfo, setCourseInfo] = React.useState('');
    const [deleteopen, setDeleteOpen] = React.useState(false);
    const [delcourseid, setDeleteCourseId] = React.useState('');
    //Filter:
    const [filtervalue, setFilterValue] = React.useState('all')

    const [deletebutton, setdeletebutton] = React.useState(false)
    const[tableloading,setTableloading]=React.useState(true)

    const open2 = Boolean(anchorEl2);
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };


    const handleChangePage = (event, newPage) => {

        
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

    React.useEffect(() => {
        getCategory()
    }, [rowsPerPage,page])

    const getCategory = async () => {
        
        
        await axios.get(process.env.REACT_APP_BASE_URL + `category/GetCategory?size=${rowsPerPage}&page=${page+1}&order=asc`, { headers: headerlocal })
            .then((res) => {

             
                
             
                setCat(res.data.category)
            }).catch((error) => {
                console.log(error.response, "error");
            })
    }

  
    

      const handleClick = (event,data) => {
        setAnchorEl(event.currentTarget);
        setAlldata(data)
    };

    const handleClose_menu = () => {
        setAnchorEl(null);
    };

    const Gridview = () => {
        setView(false)
    }
    const Listview = () => {
        setView(true)
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(newdata);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        var fromvar = [...items]
        var intial = fromvar[result.source.index]
        var final = fromvar[result.destination.index]
        var before = fromvar[result.destination.index - 1]
        var after = fromvar[result.destination.index + 1]
        let currElIndexNumber = 0;


        if (before === undefined || before === "undefined") {
            currElIndexNumber = after.index_position + 512;
        } else if (after == undefined || after == "undefined") {
            currElIndexNumber = before.index_position - 512;
        } else if (before.setService == true && after.setService == false) {
            currElIndexNumber = before.index_position - 512;
        } else if (before.setService == false && after.setService == true) {
            currElIndexNumber = after.index_position + 512;

        } else if (before.setService == false && after.setService == true) {
            currElIndexNumber = before.index_position - 512;
        } else if (before.setService == true && after.setService == false) {
            currElIndexNumber = after.index_position + 512;
        }
        else {
            currElIndexNumber = Math.floor((before.index_position + after.index_position) / 2);
        }

        setNewdata(fromvar);

        var body = {
            index_position: currElIndexNumber
        }

        axios.put(process.env.REACT_APP_BASE_URL + `service/updateServiceposition/${final._id}`, body, { headers: headerlocal })
            .then((res) => {
            
                // getService();
            }).catch((err) =>
                console.log(err.response, "drag & drop")
            )
    }


    // React.useEffect(() => {
    //     getCourse()
    // }, [rowsPerPage, page, search, catid])

    // const getCourse = async () => {
    //     await axios.get(process.env.REACT_APP_BASE_URL + `Course/GetCourse?createdby=${params && params.id}&size=${rowsPerPage}&page=${page}&search=${search}&Category_id=${catid}`, { headers: headerlocal }).then((res) => {
    //         setNewdata(res.data.course)
    //         setTotal(res.data.totalcount)
    //     }).catch((error) => {
    //         console.log(error, "error in service get");
    //     })
    // }


    const handleDeleteService = async () => {
      
        
        await axios.put(process.env.REACT_APP_BASE_URL + `Course/deleteCourse/${deleteid}`, {}, { headers: headerlocal }).then((res) => {
            enqueueSnackbar("Course deleted successfully", { variant: 'success' })
            // getCourse()
            handleClose()
        })
            .catch((error) => {
                console.log(error);
            })
    }


    const handleClickOpen = () => {
        


        setDeleteOpen(true)
        setDeleteCourseId(alldata._id)
    };

    const handleClose = () => {
        setDeleteId('')
        setOpen(false);
        setDeleteOpen(false)
        setDeleteCourseId('')
    };

    const handleEditCard = () => {

      
      if (alldata.mode_of_course === 'digitaldownload') {
        navigate(`/teachers/details/course/edit/${alldata._id}`)
        return;

    }
    // navigate(`/teachers/details/course/edit/${params.id}`, { state: val })
    navigate(`/addcourse/courses/details/${alldata._id}`)
  }

    const handleEdit = (row) => {

        if (row.mode_of_course === 'digitaldownload') {
            navigate(`/teachers/details/course/edit/${row._id}`)
            return;

        }
        // navigate(`/teachers/details/course/edit/${params.id}`, { state: val })
        navigate(`/addcourse/courses/details/${row._id}`)
    }

    const handleView = () => {
        setAnchorEl(null);
        setOpenview(true)

    }

    const handleRemove = () => {
        setCatId('all')
        setSearch('')
        setPage(0)
    }


    // const sanitizedContent = DOMPurify.sanitize(alldata.description, { ALLOWED_TAGS: ['img'] });

    const handleClickClose = () => {
        setOpenview(false)
        setAlldata('')
    }

    const handleChangeStatus = async (e, row) => {
        const newStatus = e.target.checked;

      
        try {
          // API endpoint to approve/reject the course
          const res = await axios.put(process.env.REACT_APP_BASE_URL + `Course1/Updateapprove_course/${row._id}`, 
          {
            approve_course: newStatus, // This should be the new status (true/false)
          });
          if(res.data.update.approve_course){
            enqueueSnackbar("Course is approved", { variant: 'success' });
          }
          else{
            enqueueSnackbar("Course is disapproved", { variant: 'success' });

          }
          CourseTable()
          
        } catch (error) {
          console.error('API error:', error);
          // Optionally, revert the switch if the API call fails
        }
      };


    //Hook's State:
    React.useEffect(() => {
        CourseTable()
    }, [rowsPerPage, search,filtervalue,page])



    const CourseTable = () => {
      
      setTableloading(true)
        axios.get(process.env.REACT_APP_BASE_URL + `Course1/GetCourseallpublic?mode_of_course=${filtervalue}&size=${rowsPerPage}&page=${page}&search=${search}`)
            .then((res) => {                
                setCourseInfo(res.data.course);
                setTotal(res.data.totalcount)
            })
            .catch((err) => {
                console.log(err, "error in CourseTable");
            })
            .finally(()=>{
              setTimeout(() => {
                  setTableloading(false);
              }, 1000);
          })
    }


    //Delete functionality:
    const handleDelete = (data) => {
 
        setDeleteOpen(true)
        setDeleteCourseId(data._id)
    }

    const DeleteCourseInfo = (id) => {

       
        setdeletebutton(true)
        axios.put(process.env.REACT_APP_BASE_URL + `Course1/DeleteCourse/${id}`)
            .then((res) => {
                CourseTable()
                enqueueSnackbar('Course Deleted Successfully', { variant: 'success' })
                setDeleteCourseId('')
                setDeleteOpen(false)
            })
            .catch((err) => {
                console.log(err, "error in DeleteCourseInfo");
            })
            .finally(()=>{
              setdeletebutton(false)
            })
    }

    const handleDeleteClose = () => {
        setDeleteOpen(false)
        setDeleteCourseId('')
    }
    function capitalizeFirstLetter(string) {
        if (!string) return string; // Check for empty string
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

//Filter:
    const handleFilter = (value) => {
        setFilterValue(value)
        setPage(0)
        handleClose2()
    }

    //search
    const handleSearch=(e)=>{
        setSearch(e.target.value);
        setPage(0)
    }



    function sumLikes(course) {
     
      let totalLikes = 0;
      if (
        course?.mode_of_course === "online" ||
        course?.mode_of_course === "prerecorded"
      ) {
        // Iterate through each chapter in the course
        course?.chapters.length > 0 &&
          course?.chapters?.forEach((chapter) => {
            // Iterate through each lesson in the chapter
            chapter?.lessons.length > 0 &&
              chapter?.lessons.forEach((lesson) => {
                // Add the number of likes for the lesson
                if(lesson?.like && lesson?.like.length > 0 ){
                  totalLikes += lesson.like.length;
                }
              });
          });

        return totalLikes;
      }
      return 0;
    }

 

    return (
      <>
        {view === false ? (
          <Box className="p-20">
            <Box className="flex-center flex-wrap mb-20 " sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box className="mr-10 mb-5">
                <Tooltip title="Grid View">
                  <Button
                    className={view === false ? "active_tab" : "inactive_tab"}
                    onClick={Gridview}
                  >
                    <GridView />
                  </Button>
                </Tooltip>
                <Tooltip title="List View">
                  {" "}
                  <Button
                    className={view === true ? "active_tab" : "inactive_tab"}
                    onClick={Listview}
                  >
                    <ListAlt />
                  </Button>
                </Tooltip>
              </Box>

              <Box className='flex-center flex-wrap mb-20'>
              <Box >
                <Button
                  id="basic-button"
                  aria-controls={open2 ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  onClick={handleClick2}
                  className="btnTransparent capitalize text_black border fz-13 semibold"
                >
                  <FilterAlt className="fz-16 mr-5" /> {filtervalue}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl2}
                  open={open2}
                  onClose={handleClose2}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="acct-menu select"
                >
                  <MenuItem onClick={() => handleFilter("all")}>All</MenuItem>
                  <MenuItem onClick={() => handleFilter("online")}>
                    Online
                  </MenuItem>
                  <MenuItem onClick={() => handleFilter("prerecorded")}>
                    Pre-recorded
                  </MenuItem>
                  <MenuItem onClick={() => handleFilter("digitaldownload")}>
                    Digital Download
                  </MenuItem>
                  <MenuItem onClick={() => handleFilter("Challenges")}>
                    Challenges
                  </MenuItem>
                </Menu>
              </Box>

              <Box component="div" className="DTsearch p-20 m-0">
                <FormControl
                  variant="standard"
                  className="justify-content-right"
                >
                  <Typography component="div" className="label mr-10">
                    Search :
                  </Typography>
                  <TextField
                    id="filled-size-small"
                    variant="outlined"
                    size="small"
                    value={search}
                    className="textfield search"
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                    placeholder="Search Here..."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {search !== "" && (
                            <IconButton onClick={handleRemove} size="small">
                              <CloseOutlined className="fz-18" />
                            </IconButton>
                          )}
                          <SearchOutlined className="fz-18" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Box>

              {catid !== "all" || search !== "" ? (
                <Button onClick={handleRemove} className="theme-btn ml-10 mb-5">
                  Reset
                </Button>
              ) : (
                ""
              )}
              </Box>

            
            </Box>


            <Box className="">
              <Grid
                container
                columnSpacing={2}
                rowSpacing={2}
                className="servicesList otherList"
              >
                {tableloading?
                                            (
                                                    <Grid
                                                        container
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        style={{ height: '100%' }}
                                                    >
                                                        <Grid item>
                                                            <CircularProgress sx={{ color: '#F3714D' }} />
                                                        </Grid>
                                                    </Grid>
                                            )
                                            :
                (courseinfo && courseinfo.length > 0 ? courseinfo?.map((data, index) => {
                                return (
                                  <Grid
                                    key={index}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    xl={3}
                                  >
                                    <Card className="cardGrid">
                                      <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={
                                          menuopen ? "long-menu" : undefined
                                        }
                                        aria-expanded={
                                          menuopen ? "true" : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={(e) => handleClick(e,data)}
                                        className="vertical_menu"
                                      >
                                        <MoreVertIcon />
                                      </IconButton>

                                      <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                          "aria-labelledby": "long-button",
                                        }}
                                        anchorEl={anchorEl}
                                        open={menuopen}
                                        onClose={handleClose_menu}
                                        PaperProps={{
                                          style: {
                                            width: "15ch",
                                          },
                                        }}
                                        transformOrigin={{
                                          horizontal: "right",
                                          vertical: "top",
                                        }}
                                        anchorOrigin={{
                                          horizontal: "right",
                                          vertical: "bottom",
                                        }}
                                      >
                                        <MenuItem
                                          className="menuitems"
                                          onClick={() => handleEditCard()}
                                        >
                                          <EditIcon className="fz-16 mr-5" />
                                          Edit
                                        </MenuItem>
                                        <MenuItem
                                          className="menuitems"
                                          onClick={(e) => handleClickOpen(e, data._id)}
                                        >
                                          <DeleteIcon className="fz-14 mr-5" />
                                          Delete
                                        </MenuItem>
                                      </Menu>

                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                      >
                                        <div className="carosuel ">
                                          <Swiper
                                            modules={[Navigation, Pagination]}
                                            className="mySwiper "
                                            slidesPerView={1}
                                            pagination={{
                                              clickable: true,
                                            }}
                                          >
                                            {/* {data &&
                                              data.allimages &&
                                              data.allimages?.length > 0 &&
                                              data.allimages.map((tdata, i) => (
                                                <SwiperSlide>
                                                  {" "}
                                                  <img
                                                    width={"100%"}
                                                    alt={"pic"}
                                                    height={250}
                                                    src={
                                                      process.env
                                                        .REACT_APP_BASE_URL +
                                                      `${tdata}`
                                                    }
                                                  />
                                                </SwiperSlide>
                                              ))} */}

                                            {/* Single Image */}
                                            <img
                                              width={"100%"}
                                              alt={"pic"}
                                              height={250}
                                              src={
                                                data.image
                                                  ? process.env
                                                      .REACT_APP_BASE_URL +
                                                    `${data.image}`
                                                  : Courseimage
                                              }
                                            />
                                          </Swiper>
                                        </div>
                                      </Grid>


                                      <CardContent>
                                        <Box
                                          className="mb-10 d-flex align-items-center justify-content-between"
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Typography
                                            noWrap
                                            className="semibold title fz-18"
                                            variant="body1"
                                            component="div"
                                          >
                                            {data.course_title}
                                          </Typography>
                                        </Box>

                                        <Box className="mb-10 flex-center flex-wrap  justify-content-between">
                                          <Box className=" ">
                                            <Typography
                                              component="div"
                                              variant="body1"
                                              className="fz-14 semibold"
                                            >
                                              Approved:{" "}
                                            </Typography>
                                            <FormControlLabel
                                              className="m-0"
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                              control={
                                                <Switch
                                                  size="small"
                                                  onChange={(e) =>
                                                    handleChangeStatus(e, data)
                                                  }
                                                  checked={data.approve_course}
                                                />
                                              }
                                            />
                                          </Box>
                                          <Box className=" ">
                                            <Button
                                              disableRipple
                                              className="btnTransparent p-0 mr-10 ml-10"
                                            >
                                              <i class="fa-solid fa-thumbs-up text_primary fz-16"></i>
                                              <Typography
                                                className="fz-10 text_primary pb-12"
                                                component="span"
                                              >
                                                {sumLikes(data)}
                                              </Typography>
                                            </Button>
                                          </Box>
                                        </Box>

                                        {data && data.shortDescription ? (
                                          <Typography
                                            component="div"
                                            variant="body1"
                                            className="fz-14 "
                                          >
                                            {data &&
                                              data.shortDescription.slice(
                                                0,
                                                150
                                              ) + "..."}
                                          </Typography>
                                        ) : (
                                          <Typography
                                            component="div"
                                            variant="body1"
                                            className="fz-14 "
                                          >
                                            There is no description
                                          </Typography>
                                        )}
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                );
                            }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                                <p className='text-center semibold'>No data found</p></Grid>
                                )}
              </Grid>
            </Box>
            
            
          </Box>
        ) : (
          <>
          <Box className='p-20'>
          <Box className="flex-center flex-wrap mb-20 " sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box className="">
                <Tooltip title="Grid View">
                  <Button
                    className={view === false ? "active_tab" : "inactive_tab"}
                    onClick={Gridview}
                  >
                    <GridView />
                  </Button>
                </Tooltip>
                <Tooltip title="List View">
                  {" "}
                  <Button
                    className={view === true ? "active_tab" : "inactive_tab"}
                    onClick={Listview}
                  >
                    <ListAlt />
                  </Button>
                </Tooltip>
              </Box>

              <Box className='flex-center flex-wrap mb-20'>
              <Box >
                <Button
                  id="basic-button"
                  aria-controls={open2 ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  onClick={handleClick2}
                  className="btnTransparent capitalize text_black border fz-13 semibold"
                >
                  <FilterAlt className="fz-16 mr-5" /> {filtervalue}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl2}
                  open={open2}
                  onClose={handleClose2}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="acct-menu select"
                >
                  <MenuItem onClick={() => handleFilter("all")}>All</MenuItem>
                  <MenuItem onClick={() => handleFilter("online")}>
                    Online
                  </MenuItem>
                  <MenuItem onClick={() => handleFilter("prerecorded")}>
                    Pre-recorded
                  </MenuItem>
                  <MenuItem onClick={() => handleFilter("digitaldownload")}>
                    Digital Download
                  </MenuItem>
                  <MenuItem onClick={() => handleFilter("digitaldownload")}>
                    Challenges
                  </MenuItem>
                </Menu>
              </Box>

              <Box component="div" className="DTsearch p-20 m-0">
                <FormControl
                  variant="standard"
                  className="justify-content-right"
                >
                  <Typography component="div" className="label mr-10">
                    Search :
                  </Typography>
                  <TextField
                    id="filled-size-small"
                    variant="outlined"
                    size="small"
                    value={search}
                    className="textfield search"
                    onChange={(e) => {
                      handleSearch(e);
                    }}
                    placeholder="Search Here..."
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {search !== "" && (
                            <IconButton onClick={handleRemove} size="small">
                              <CloseOutlined className="fz-18" />
                            </IconButton>
                          )}
                          <SearchOutlined className="fz-18" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Box>
              {catid !== "all" || search !== "" ? (
                <Button onClick={handleRemove} className="theme-btn ml-10 mb-5">
                  Reset
                </Button>
              ) : (
                ""
              )}
              </Box>

              

          </Box>
          

      


        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <TableHead className="DT-head">
              <TableRow className="">
                <TableCell
                  align="left"
                  className="DT-checkbox TRow semibold"
                  style={{ minWidth: 200, padding: "6px 16px" }}
                >
                  Course name
                </TableCell>
                {/* <TableCell align="left" className='DT-checkbox TRow semibold' style={{ minWidth: 200, padding: '6px 16px' }}>Mega Event</TableCell> */}
                <TableCell
                  align="center"
                  className="DT-checkbox TRow semibold"
                  style={{ minWidth: 200, padding: "6px 16px" }}
                >
                  Course Mode
                </TableCell>
                <TableCell
                  align="center"
                  className="DT-checkbox TRow semibold"
                  style={{ minWidth: 200, padding: "6px 16px" }}
                >
                  Created On
                </TableCell>
                <Typography
                  align="center"
                  className="DT-checkbox TRow semibold"
                  style={{ minWidth: 200, padding: "6px 16px" }}
                >
                  Approved:
                </Typography>
                
                <TableCell
                  align="center"
                  className="DT-checkbox TRow semibold"
                  style={{ minWidth: 200, padding: "6px 16px" }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className="characters ">
              {courseinfo && courseinfo?.length > 0 ? (
                courseinfo.map((row, i) => (
                  <>
                    <TableRow className="" key={i}>
                      {row && row.course_title ? (
                        <TableCell
                          className="DT-row"
                          align="left"
                          style={{ minWidth: 200 }}
                        >
                          {row && row.course_title}
                        </TableCell>
                      ) : (
                        <TableCell className="DT-row" style={{ minWidth: 200 }}>
                          -
                        </TableCell>
                      )}

                     

                      {row && row.mode_of_course && (
                        <StyledTableCell align="center" className="DT-row">
                          <Typography component="div" className="modetype">
                            {capitalizeFirstLetter(row.mode_of_course)}
                          </Typography>
                        </StyledTableCell>
                      )}

                      <StyledTableCell align="center" className="DT-row">
                        {moment(row.createdAt).format("L")}
                      </StyledTableCell>

                      <StyledTableCell align="center" className="DT-row">
                        <FormControlLabel
                          className="m-0"
                          // style={{ display: "flex", alignItems: "center" }}
                          control={
                            <Switch
                              size="small"
                              onChange={(e) => handleChangeStatus(e, row)}
                              checked={row.approve_course}
                            />
                          }
                        />
                      </StyledTableCell>

                    

                      <StyledTableCell align="center" className="DT-row">
                        <EditIcon
                          onClick={() => handleEdit(row)}
                          className="fz-16 mr-5"
                        />

                        <DeleteIcon
                          onClick={() => handleDelete(row)}
                          className="fz-14 mr-5"
                        />
                      </StyledTableCell>
                    </TableRow>
                  </>
                ))
              ) : (
                <TableRow className="">
                  <TableCell className="DT-row" align="center" colSpan={5}>
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[8, 12, 16, 20, 24]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="DT-pagination"
        />
        </Box>
          </>
        )}
        
        {/* Delete Course */}
        <Dialog
          open={deleteopen}
          keepMounted
          onClose={handleDeleteClose}
          aria-describedby="alert-dialog-slide-description"
          className="custom_modal"
        >
          <DialogTitle>{"Would you like to delete this course?"}</DialogTitle>
          <DialogActions>
            <Button onClick={handleDeleteClose} className="cancel_btn">
              No
            </Button>
            {
              deletebutton?
              <Button
             
              className="theme-btn"
            >
              Please wait...
            </Button>
            :
            <Button
              onClick={() => DeleteCourseInfo(delcourseid)}
              className="theme-btn"
            >
              Yes
            </Button>

            }
            
          </DialogActions>
        </Dialog>
      </>
    );
}
