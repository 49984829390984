import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid,Tooltip, Checkbox,InputAdornment, Avatar, Input, TextField, FormControlLabel, RadioGroup, Radio, Typography, IconButton, TextareaAutosize } from '@mui/material'
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom'
import Switch from '@mui/material/Switch';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import {AddAPhoto, Category, Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import Avatarimage from "../../../assets/images/Avatar.png"
import PropTypes from 'prop-types';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { usePlacesWidget } from "react-google-autocomplete";
import AutoComplete from "./AutoComplete";
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#f3714d',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});

export default function AddMerit() {
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem("userData"))
    let location = useLocation()
    var data = location.state ? location.state.result : null
    const [truebutton, setTruebutton] = React.useState(false)
    const [user, setUser] = React.useState({ firstname: '', username: '', email: '', gender: 'Male', phone: '', instagram:'', facebook:'',twitter:'', youtube:'',pinterest:'', tiktok:'' ,Imageprew: "", Imageraw: "", specialization: [''], description: "", plan: '', userid: '' });
    const [error, setError] = React.useState({ Locations:'',firstname: "", image: "", specialization: "", link: "", location: "" ,description:''})
    const [isToggled, setToggled] = React.useState(false)
    const [statesingle, setStatesingle] = React.useState([])
    const [statesingle2, setStatesingle2] = React.useState([])
    const [cityName, setCityName] = React.useState('')
    const [lat, setLat] = React.useState('')
    const [lng, setLng] = React.useState('')
    const [link, setLink] = React.useState('')
    const [merit, setMerit] = React.useState(true)
    const [isApproveChecked, setIsApproveChecked] = React.useState(false)
    const [isChecked, setChecked] = React.useState(false)
    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };
    let username, uservalue;
    const handleChange = (e) => {
        const hasAlphanumeric = /[a-zA-Z]/;
        username = e.target.name;
        uservalue = e.target.value;

        setError({ ...error, [username]: '' })
        setError('')
        if (username === 'location') {
            setCityName(uservalue);
            setError({ ...error, location: '' });
        }
        if (username === 'specialization') {
            if (!hasAlphanumeric.test(uservalue)) {
                setError({ ...error, specialization: "Specialization field must contain at least one letter." });
            } else {
                setError({ ...error, specialization: "" });
            }
        }
        setUser({ ...user, [username]: uservalue })

        if (username === 'firstname') {
            if (!hasAlphanumeric.test(uservalue)) {

                if(/^[^a-zA-Z0-9]+$/.test(uservalue)){
          
                    setError({...error, firstname: "Name should not contain only special characters" })
                    return false
                }
                if (/^\d+$/.test(uservalue)) {
                    setError({...error, firstname: "Name should not be only numbers" })
                    return false
                    
                  }
                // setError({ ...error, firstname: "Name field can't contain only numbers and special characters." });
            } else {
                setError({ ...error, firstname: "" });
            }
        }
    };

    const SingleImage = (e, index) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 1) {
            setError({ image: 'Image should be less than 1 MB' });
            setUser({
                ...user,
                Imageprew: '',
                Imageraw: null,
            });
        } else {
            setUser({
                ...user,
                Imageprew: URL.createObjectURL(file),
                Imageraw: file,
            });
            setError({ image: '' });
        }
    };


    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }
    const handleaddphoto = (e) => {
        setUser({ ...user, Imageprew: URL.createObjectURL(e.target.files[0]), Imageraw: e.target.files[0] })
        setError('')
    }

    const handleToggle = () => {
        setToggled(!isToggled)
    }

    const { ref, autocompleteRef } = usePlacesWidget({
        // need to change this API key
        apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
        onPlaceSelected: (place) => {
            setCityName(place.formatted_address)
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
            setError(prevError => ({ ...prevError, location: '' }))
        },
        options: {
            types: "(regions)" | 'establishment'
        },
    });
    const handleChecked = () => {
        setChecked(!isChecked)
    }

    const urlRegex = /^(ftp|http[s]?):\/\/(?:www\.)?([^\s.]+\.[^\s]{2,}|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(:\d+)?(\/\S*)?$/;
    const validation = () => {
        let isValid = true;
        const spacePattern = /\s/;
        const errors = {};

       

        const trimmedValue = user.username.trim();

        if (!user.username || trimmedValue === '') {
            errors.username = "This Filed is required";
            isValid = false;
        }
        if(spacePattern.test(user.username))
        {
            errors.username = "Username should not contain spaces";
            isValid = false;
        }

      
        
        if(!user.description)
        {
            errors.description =  "This Filed is required";
            isValid = false;
        }


        if (!user.firstname ) {
            errors.firstname = "This Filed is required ";
            isValid = false;
        }

if(!cityName)
{
    errors.Locations="This Filed is required";
            isValid = false;
}
        if(!user.phone){
            errors.phone = "This Filed is required";
            isValid = false;
        }

        if (!user.email) {
            errors.email = "This Filed is required";
            isValid = false;
        } else {
            const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
            if (!emailPattern.test(user.email)) {
                errors.email = "Please enter a valid Email";
                isValid = false;
            }
        }

        setError(errors);
        return isValid;
    };

    // const addMeritDetail = () => {
    //     const validator = validation()
    //     if (validator == true) {
    //         setTruebutton(true)
    //         const formData = new FormData();
    //         { statesingle2 ? statesingle2.forEach((item) => { formData.append("image", item) }) : formData.append("image", '') }
    //         formData.append("name", user.firstname);
    //         formData.append("merit", merit);
    //         formData.append("specialization", user.specialization);
    //         formData.append("link", link); // Corrected typo in "link" key
    //         formData.append("address", cityName); // Corrected typo in "location" key
    //         for (const value of formData.values()) {
    //             console.log(value);
    //         }
    //         axios.post(process.env.REACT_APP_BASE_URL + `Admin/addyogaMerits`, formData, { headers: headerformdata })
    //             .then((res) => {
    //                 setTruebutton(false)
            
    //                 setUser({ firstname: '', specialization: "" });
    //                 setLink('')
    //                 setStatesingle('')
    //                 setCityName('')
    //                 navigate('/teachermerit')
    //             })
    //             .catch((err) => {
    //                 setTruebutton(false)
    //                 console.error("Error occurred:", err);
    //                 if (err.response) {
        
    //                     enqueueSnackbar("Server responded with an error", { variant: 'error' });
    //                 } else if (err.request) {
    //                     console.error("Request made but no response received:", err.request);
    //                     enqueueSnackbar("No response received from the server", { variant: 'error' });
    //                 } else {
    //                     console.error("Error setting up the request:", err.message);
    //                     enqueueSnackbar("Error setting up the request", { variant: 'error' });
    //                 }
    //             });
    //     }
    // }
    const handleRemoveSpecialization = (index) => {
        const updatedSpecializations = [...user.specialization];
        updatedSpecializations.splice(index, 1);
        setUser({ ...user, specialization: updatedSpecializations });
    };

    const handleAddSpecialization = () => {
        setUser({ ...user, specialization: [...user.specialization, ''] });
    };

    const handleApproveChecked = () => {
        setIsApproveChecked(!isChecked)
    }

    const addMeritDetails = async(e,details) => {


      
        
        const validator = validation()
        if (validator == true) {
            setTruebutton(true)
            const formData = new FormData();
          
            formData.append("usertype",'Legends')
            formData.append("image", user.Imageraw ? user.Imageraw : "");
            formData.append("name", user.firstname);
            formData.append("username", user.username);
            formData.append("email", user.email);
            formData.append("bundleApproved", isApproveChecked);
            formData.append("role_id", 2);
            formData.append("role","Teachermerit")
            formData.append("merit", true)
            // formData.append("specialization", user.specialization);
            const transformedSpecializations = user.specialization.flatMap(spec => spec.split(',').map(s => s.trim())).filter(Boolean);
            transformedSpecializations.forEach((spec, index) => {
                formData.append(`specialization[${index}]`, spec);
            });
            formData.append("jobApproveByAdmin",false)
            formData.append("address", cityName);
            formData.append("latitude", lat);
            formData.append("longitude", lng);
            formData.append("description", user.description ? user.description : '');
            formData.append("contact_number", user.phone);   
            formData.append("gender", user.gender ? user.gender : '');
            formData.append("instagram", user.instagram ? user.instagram : '')
            formData.append("facebook", user.facebook ? user.facebook : '' )
            formData.append("twitter", user.twitter ? user.twitter : '')
            formData.append("youtube", user.youtube ? user.youtube : '')
            formData.append("pinterest", user.pinterest ? user.pinterest : '')
            formData.append("tiktok", user.tiktok ? user.tiktok : '')
            formData.append("addTeacherForm", 'Admin')
            console.log([...formData],"details fetch");
            for (const value of formData.values()) {
                console.log(value);
            }


               await axios.post(process.env.REACT_APP_BASE_URL+"Admin/addYogaMerits",formData)
                .then((res) => {
                    setTruebutton(false)
                    
                    console.log(res.data,"add merits");
                    
                    const { planId, _id }  = res.data.addyogaMerits;
                    console.log(_id,"resaddlegend");
                    
                    
                    if (details ==='addWithNavigate') {
                        console.log("working")     
                        setUser({ firstname: '', email: '', SetVerifyTeacher: "", gender: 'Male', phone: '', Imageprew: "", Imageraw: "", specialization: "", description: "" });
                        console.log(user,"working");
                        navigate(`/teachers/details/events/${"Teachermerit"}/${_id}`);
                    } else if (details === 'add') {
                        setUser({ firstname: '', email: '', SetVerifyTeacher: "", gender: 'Male', phone: '', Imageprew: "", Imageraw: "", specialization: "", description: "" })
                        setCityName('')
                        enqueueSnackbar("Yoga Legend  added successfully", { variant: 'success' });
                        navigate('/teachermerit')
                    }

            })
            .catch((err) => {
                setTruebutton(false)
                console.log(err, "error------");
                enqueueSnackbar(err.response?.data.error ? err.response?.data.error : err.response?.data ? err.response.data.message : "Error occurred", { variant: 'error' });
            });

            
        }
    }


    return (
        <Box className='Addcompany'>
            <MiniDrawer menuOrder={2} submenuOrder={7} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Add Teachers of Great Merit</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                  

                                <Box className='personal-info-tab'>
                                            <Typography component='div' className='label' >
                                                <center>Profile Photo<Typography component='span' className='star'></Typography></center>
                                            </Typography>
                                            <Box className='profile-photo'>
                                                <label htmlFor="icon-button-file">
                                                    <Input accept="image/*" id="icon-button-file" className='profUploadbtn' type="file" onChange={SingleImage} />
                                                    <IconButton color="#f3714d" aria-label="upload picture" component="span">
                                                        {user.Imageprew ? <Avatar src={user.Imageprew}>
                                                        </Avatar> : <Avatar src={Avatarimage} />
                                                        }
                                                        <AddAPhoto />
                                                    </IconButton>
                                                </label>
                                            </Box>
                                            <Typography className='allow-filetypes'>
                                                Allowed *.jpeg, *.jpg, *.png<br />max size of 1 MB
                                            </Typography>
                                            {/* <span style={{ color: "red", fontSize: 12 }} className='text-center'>{error.image}</span> */}

                                        </Box>
                                 

                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Tagline/Specialization</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='specialization'
                                                className='textfield'
                                                placeholder='Specialization'
                                                value={user.specialization}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    {error.specialization && <p style={{ color: 'red' }} className="fz-13 m-0">{error.specialization}</p>}

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Location<Typography component='span' className='star'>*</Typography></Typography>
                                            <AutoComplete
                                                cityName={cityName}
                                                setCityName={setCityName}
                                                lat={lat}
                                                setLat={setLat}
                                                lng={lng}
                                                setLng={setLng}
                                                className="textfield"
                                                name='location'
                                            />
                                            {error.location && <p style={{ color: 'red' }} className="fz-13 m-0">{error.location}</p>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Website Link <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                placeholder='Link'
                                                size="small"
                                                name='link'
                                                className='textfield'
                                                style={{ width: '100%' }}
                                                value={link}
                                                onChange={(e) => {
                                                    setLink(e.target.value); setError('')
                                                }}
                                            />
                                        </FormControl>
                                        {error.link && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.link}</p>}
                                    </Grid>
                                </Grid> */}




<Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='firstname'
                                                className='textfield'
                                                placeholder='Name'
                                                value={user.firstname}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Username: <Typography component='span' className='star'>*</Typography>
                                                <Tooltip title="This name will be used to create a unique URL for teacher">
                                                    <IconButton aria-label="information">
                                                        <AiOutlineInfoCircle size={20} />
                                                    </IconButton>
                                                </Tooltip></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='username'
                                                className='textfield'
                                                placeholder='Username'
                                                value={user.username}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </FormControl>
                                        {error.username && <div className="error-message" style={{ color: "red", fontSize: 12 }}>{error.username}</div>}
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Email: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='email'
                                                className='textfield'
                                                value={user.email}
                                                placeholder='Email'
                                                onChange={(e) => { handleChange(e); setError('') }}

                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.email}</span>
                                    </Grid>

                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box className='mb-10 flex-center' >
                                            <Typography component='div' variant='body1' className='fz-14 label mb-0 '>Teacher Approved?   </Typography>
                                            <FormControlLabel className='m-0' control={<Switch  checked={isToggled} size='small' onClick={handleToggle} sx={{ m: 1 }} />} />
                                        </Box>
                                    </Grid> */}
                                  
                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Specialization: <Typography component='span' ></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='specialization'
                                                className='textfield'
                                                placeholder='Specialization'
                                                value={user.specialization}
                                                onChange={handleChange}

                                            />
                                        </FormControl>
                                    </Grid> */}

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>
                                                Specialization:
                                            </Typography>
                                            {user?.specialization.map((value, index) => (
                                                <Grid container spacing={1} alignItems="center" key={index}>
                                                    <Grid item xs={index > 0 ? 10 : 12}> {/* Adjust width for cancel icon */}
                                                        <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            className='textfield'
                                                            name='specialization'
                                                            placeholder={`Specialization`}
                                                            value={value}
                                                            onChange={(e) => handleChange(e, index)}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    {index > 0 && (
                                                        <Grid item xs={2}>
                                                            <IconButton onClick={() => handleRemoveSpecialization(index)}>
                                                                <CancelIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            ))}
                                            <IconButton onClick={handleAddSpecialization}>
                                                <AddIcon />
                                            </IconButton>
                                        </FormControl>
                                    </Grid>



                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControlLabel className="checkbox_list"
                                            control={
                                                <Checkbox
                                                    checked={isApproveChecked}
                                                    onChange={handleApproveChecked}
                                                    name='checkbox-value'
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography component='div' className='label mb-0'>Would you like to make this teacher to add bundle offer?</Typography>}
                                        />
                                    </Grid>

{/* 
<Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControlLabel className="checkbox_list"
                                            control={
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={handleChecked}
                                                    name='checkbox-value'
                                                    color='primary'
                                                />
                                            }
                                            label={<Typography component='div' className='label mb-0'>Would you like to add this teacher to the Teachers of Great Merit?</Typography>}
                                        />
                                    </Grid> */}

                                    <Grid item lg={12} className='pt-0'>
                                        <Typography component='div' className='label '>Gender:</Typography>
                                        <Box className=" ">
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-customized-radios"
                                                name="gender"
                                                onChange={handleChange}
                                                value={user.gender}
                                            >
                                                <FormControlLabel value="Male" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Male</Typography>} />
                                                <FormControlLabel value="Female" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Female</Typography>} />
                                            </RadioGroup>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Phone:<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='phone'
                                                className='textfield'
                                                value={user.phone}
                                                placeholder='Phone'
                                                onChange={handleChange}
                                                onInput={(e) => onlyNumbers(e)}
                                                inputProps={{ maxLength: 10 }}
                                            />
                                            {error.phone && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.phone}</p>}

                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Address:</Typography>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Address"
                                                className='textfield textarea'
                                                name='address'
                                                value={user.address}
                                                onChange={handleChange}
                                                minRows={2}
                                            />
                                        </FormControl>
                                    </Grid> */}

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Location<Typography component='span' className='star'>*</Typography></Typography>
                                            <AutoComplete cityName={cityName} setCityName={setCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} className="textfield" />
                                            {error.Locations && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.Locations}</p>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Description:<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Details"
                                                className='textfield textarea'
                                                name='description'
                                                value={user.description}
                                                onChange={handleChange}
                                                minRows={3}
                                                style={{ resize: 'none' }} />
                                                   {error.description && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.description}</p>}

                                        </FormControl>
                                    </Grid>
                            {/* Add Social Media Links*/}
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Instagram Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='instagram'
                                                className='textfield'
                                                value={user.instagram}
                                                placeholder='Instagram Link'
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Facebook Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='facebook'
                                                className='textfield'
                                                value={user.facebook}
                                                placeholder='Facebook Link'
                                                onChange={handleChange}
                                            />

                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Twitter Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='twitter'
                                                className='textfield'
                                                value={user.twitter}
                                                placeholder='Twitter Link'
                                                onChange={handleChange}
                                            />

                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Youtube Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='youtube'
                                                className='textfield'
                                                value={user.youtube}
                                                placeholder='Youtube Link'
                                                onChange={handleChange}
                                            />

                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Pinterest Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='pinterest'
                                                className='textfield'
                                                value={user.pinterest}
                                                placeholder='Pinterest Link'
                                                onChange={handleChange}
                                            />

                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Tiktok Link:<Typography component='span' className='star'></Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='tiktok'
                                                className='textfield'
                                                value={user.tiktok}
                                                placeholder='Tiktok Link'
                                                onChange={handleChange}
                                            />

                                        </FormControl>
                                    </Grid>
                                    </Grid>
                                
                                    {truebutton === false ?
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10' onClick={(e) => addMeritDetails(e, 'add')}>Save</Button>
                                        <Button variant="contained" className='addbtn mr-10' onClick={(e) => addMeritDetails(e, 'addWithNavigate')}>Save + Continue</Button>
                                    </Box>
                                    : truebutton === true &&
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10'>Please Wait</Button>
                                    </Box>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}