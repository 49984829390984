import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import DataTable from './Datatable'

export default function Tag() {
    let navigate = useNavigate()
    const role = JSON.parse(localStorage.getItem("roledata"))

    const addTag = () => {
        navigate('/addtag');
    }
    return (
        <Box className='companies'>
            <MiniDrawer menuOrder={1} submenuOrder={3} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Tags</Typography>
                    {role.UserRoles == null ?
                        <Button variant="contained" className='addbtn' onClick={() => addTag()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Tag</Button>
                        : role.UserRoles != null && role.UserRoles.tags.Addtags &&
                        <Button variant="contained" className='addbtn' onClick={() => addTag()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Tag</Button>
                    }                </Box>
                <Box className='whiteContainer'>
                    <DataTable />
                </Box>
            </Box>
        </Box>
    )
}