import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import MiniDrawer from "../../../components/drawer";
import BarCharts from "./BarCharts";
import Report from "./reports";
import SiteVisitorChart from "./reportgraph";
import FanCard from "./Fanzs";
import JobChart from "./Jobsgraph";
import ElearningChart from "./elearningchart";

export default function Dashtab() {
	return (
		<>
			<Box className="companies dashboard">
				<MiniDrawer menuOrder={1} submenuOrder={1} />
				<Box component="section" className="contentWraper">
					<Box className="headingBox">
						<Typography
							component="h4"
							variant="h4"
							className="Submenu-page-heading formTitle"
						>
							Reports
						</Typography>
					</Box>
					<Box className="whiteContainer">
						<Box className="p-20">
							<Grid container columnSpacing={6} rowSpacing={6}>
								<Grid item className="" xs={12} md={12} lg={6}>
									<SiteVisitorChart />
								</Grid>
								<Grid item className="" xs={12} md={12} lg={6}>
									<JobChart />
								</Grid>
								<Grid item  xs={12} md={12} lg={6} className="d-flex">
									<FanCard />
								</Grid>
								<Grid item className="" xs={12} md={12} lg={6}>
									<ElearningChart />
								</Grid>

								<Grid item className="" xs={12} md={12} lg={12}>
									<Report />
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
}
