import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Grid, FormControl, TextField, InputAdornment, IconButton,CircularProgress } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCommunity } from "../Context/CommunityContext";
import { MdDelete } from "react-icons/md";
import { useParams } from "react-router-dom";

export default function DataTable({ params }) {
    let tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    const { fetchCommunity } = useCommunity();
    const { enqueueSnackbar } = useSnackbar();

    const {id} = useParams()

    const [addDisable, setAddDisable] = useState(false);
    const [communityRules, setCommunityRules] = useState([{ name: '' }]);
    const [errors, setErrors] = useState([]);
    const [community, setCommunity] = React.useState([])
    const [rules,setRules]=useState([]);
    const[Tableloading,setTableloading]=React.useState(true)
    const[Buttondisalbe,setButtondisable]=React.useState(false)
    



    const handleCommunityRuleChange = (index, value) => {
        const updatedRules = [...communityRules];
        updatedRules[index] = { ...updatedRules[index], name: value };
        setCommunityRules(updatedRules);

        // Clear the error message for the current rule
        const updatedErrors = [...errors];
        updatedErrors[index] = '';
        setErrors(updatedErrors);
    }

    const addCommunityRule = () => {
        setCommunityRules([...communityRules, { name: '' }]);
        setErrors([...errors, '']);
    }

    const removeCommunityRule = (index) => {
        const updatedRules = [...communityRules];
        updatedRules.splice(index, 1);
        setCommunityRules(updatedRules);

        const updatedErrors = [...errors];
        updatedErrors.splice(index, 1);
        setErrors(updatedErrors);
    }


    React.useEffect(() => {
        getCommunity()
    }, [])

    const getCommunity = async () => {
        setTableloading(true)
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Community/getCommunityBasedOnId/${params.id}`);
            setCommunity(response.data.community);
            setRules(response.data.community.rules)
            console.log(response.data, 'cs');
        } catch (error) {
            console.log(error.response, "error");
        }
        finally{
            setTimeout(() => {
                setTableloading(false);
            }, 1000);
        }
    }

    

    const validateRules = () => {
        let isValid = true;
        const newErrors = communityRules.map(rule => {
            if (!rule.name.trim()) {
                isValid = false;
                return 'This Field is Required.';
            }
            return '';
        });
        setErrors(newErrors);
        return isValid;
    }
    
    const addRuleDetails = () => {
        if (!validateRules()) {
            return;
        }

        let body = {
            rules: communityRules.map(rule => rule.name),
            community_id: params && params.id,  
        };
     setAddDisable(true)
        axios.post(process.env.REACT_APP_BASE_URL + `community/updateCommunityRules/${params.id}`, body, tokendata)
            .then((res) => {
                fetchCommunity();
                setCommunityRules([{ name: '' }]);
                setErrors(['']);
                getCommunity()
                enqueueSnackbar("Community Rules added successfully", { variant: 'success' });
            }).catch((err) => {
                enqueueSnackbar(err && err.response && err.response.message ? err.response.message : "Something went wrong", { variant: 'error' })
            })
            .finally(()=>{
                setAddDisable(false)
            })
    }
// const handledeleterules=(e, item, index)=>{
    
//     console.log(e, item, index,"jhvuvv");

    
//  let  removerules = rules.filter((data)=>{return data!=item})

// setRules(removerules)
// enqueueSnackbar("Community Rules deleted successfully", { variant: 'success' });
 
    
    

// }

const handledeleterules = (e, item, index) => {
    console.log(item, index, "jhvuvv");
  
    // Remove the rule from the local state first (optimistic update)
    let removerules = rules.filter((data) => data !== item);
    setRules(removerules);
  
    // Make network call to delete the rule from the backend
    axios
      .put(process.env.REACT_APP_BASE_URL + `Community/deleteCommunityRule/${id}?index=${index}`, {
        
      })
      .then((response) => {
        // Handle success (You can log the response if needed)
        console.log(response.data);
        
        // Show success snackbar notification
        enqueueSnackbar("Community Rules deleted successfully", { variant: 'success' });
      })
      .catch((error) => {
        // Handle error and revert the UI if needed
        console.log(error, "error in deleting community rule");
  
        // Revert state update in case of error (optimistic update failure)
        setRules(rules);
  
        // Show error snackbar notification
        enqueueSnackbar("Failed to delete Community Rule", { variant: 'error' });
      });
  };
  
    

    return (
        <Box sx={{ width: '100%' }} className="whiteContainer ">
  
  
  <Box className="whiteContainer newmerchant mb-20">
                <Typography component='h6' className='formTitle'>Select Community Rules</Typography>

                <Grid container className='mb-20 '>
                    <Grid item sm={12} md={12} className="w-100">
                        <Box className="form-box staff_list">
                            <Grid container columnSpacing={2} rowSpacing={2}>
                                {Tableloading?
                            (
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{ height: '100%' }}
                                    >
                                        <Grid item>
                                            <CircularProgress sx={{ color: '#F3714D' }} />
                                        </Grid>
                                    </Grid>
                            )
                            :
                                
                                
                                
                                (rules && rules.length > 0 ?
                                    
                                    
                                    
                                    rules.map((item, index) => {


                                  return  (
                                    
                                    <Grid item sm={12} md={12} lg={5} className="w-100 d-flex" >
                                        <Box
                                            key={index}
                                           
                                            className={ "selected_staff staff_card" }
                                            sx={{marginLeft:'70px',display:'flex',justifyContent:"space-between"}}
                                        >
                                             {/* <Check className="checksvg"/>
                                            <Avatar className='mr-10' src={process.env.REACT_APP_BASE_URL + item.image}>
                                                {item.event_name && item.event_name.substring(0, 1)}
                                            </Avatar> */}
                                            
                                            <Box >

                                                {item.length<=40?(
                                                    <Box className='fz-16 semibold'>{item}</Box>
                                                ):(
                                                    <Box className='fz-16 semibold'>
                                                    {item.length > 70 ? item.substring(0, 70) + '...' : item}
                                                  </Box>
                                                )}
                                                <Box sx={{marginLeft:'380px'}} >

                                                <MdDelete   onClick={(e) => handledeleterules(e, item, index)}/>
                                                </Box>
                                                
                                                
                                                {/* {category && category?.length > 0 ? category.map((data) => (
                                                    <Box className="fz-13" value={data._id}>{data.category_title}</Box>)) : <Box disabled>{"No Record's found"}</Box>} */}
                                            </Box>  
                                        </Box>
                                    </Grid>
                                )
                            
                            }
                        
                        ) : <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                style={{ height: '100%' }}
                            >
                                <Grid item>
                                    <Typography variant="body1" className='text-center semibold'>No rules found</Typography>
                                </Grid>
                            </Grid>)}
                            </Grid>
                        </Box>

                    </Grid>
                </Grid>
                {/* <Box className="text-right ">
                    {
                        Buttondisalbe?
                        <Button variant="contained "
                        className='theme-btn mt-20'>Please wait...</Button> 
                        :
                        <Button variant="contained "
                        className='theme-btn mt-20'>Submit</Button> 

                    }
                 
                </Box> */}
            </Box>




























            <Grid container className='newmerchant'>
            <Typography component='h6' className='formTitle'>Add Community Rules</Typography>

                <Grid item sm={12} md={12} className="w-100">
                    <Box className="form-box staff_list">
                        {communityRules.map((rule, index) => (
                            <Grid container spacing={2} key={index}>
                                <Grid item xs={6} sm={6} md={6}>
                                    <FormControl variant="standard" fullWidth>
                                        <Typography component='div' className='label'>Community Rule <Typography component='span' className='star'>*</Typography></Typography>
                                        <TextField
                                            id={`rule_${index}`}
                                            variant="outlined"
                                            size="small"
                                            name={`rule_${index}`}
                                            placeholder='Community Rule'
                                            podcasts    className='textfield'
                                            style={{ width: '100%' }}
                                            value={rule.name || ''}
                                            onChange={(e) => handleCommunityRuleChange(index, e.target.value)}
                                            InputProps={{ startAdornment: <InputAdornment position="start"></InputAdornment> }}
                                            error={!!errors[index]}
                                            helperText={errors[index]}
                                        />
                                    </FormControl>
                                </Grid>
                                {index > 0 && (
                                    <Grid item xs={6} sm={6} md={6} className="d-flex align-items-end">
                                        <IconButton onClick={() => removeCommunityRule(index)}>
                                            <Delete />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Grid>
                        ))}
                        <Grid item  xs={6} sm={6} md={6} className="text-right">
                            <Button onClick={addCommunityRule} className="theme-btn">
                                <Add /> Add Community Rule
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="text-right">
                            <Button variant="contained" onClick={addRuleDetails} disabled={addDisable}  className='theme-btn'>
                                {addDisable ? 'Please wait...' : 'Submit'}
                            </Button>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
