import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import Sidebar from './../../Sidebar'
import { useNavigate, useParams } from 'react-router-dom'
import MiniDrawer from '../../../../../components/drawer'
import SliderTable from '../../../Teachers/Submodule/FansPagesliders/SliderTable'

export default function FansPageSliders() {
    const params = useParams()
    const auth = JSON.parse(localStorage.getItem("userData"))
    let navigate = useNavigate()
    const addFans = () => {
         navigate(`/teachers/details/addfanssliders/${params.role}/${params.id}`);
    }


    return (
        <Box className={auth.role_id == 1 ? 'withSubmenu' : ''}>
        {auth.role_id == 1 ? <MiniDrawer menuOrder={2} submenuOrder={1} />
            : <MiniDrawer menuOrder={21} />}
        {auth.role_id == 1 ? <Sidebar subtype={7} /> : ''}
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Fan Page</Typography>
                    <Button variant="contained" className='addbtn' onClick={() => addFans()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Sliders</Button>
                </Box>
                <Box className='whiteContainer'>
                <SliderTable />
                </Box>
            </Box>
        </Box>
    )
}


