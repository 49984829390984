import * as React from 'react';
import { Box, Button, Divider, Grid, IconButton, TextField, Typography, styled } from '@mui/material';
import userImg from '../../../../assets/images/teachers3.png'
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import postImg2 from '../../../../assets/images/eventsBanner.png'
import member1 from '../../../../assets/images/teachers1.png'
import Spacing from '../../../../components/Spacing';
import DialogTitle from '@mui/material/DialogTitle';
// import pod1 from '../../../../assets/images/pod1.jpg'
// import pod2 from '../../../../assets/images/pod2.jpg'
// import pod3 from '../../../../assets/images/pod3.jpg'
import DialogActions from '@mui/material/DialogActions';
import playBtn from '../../../../assets/images/play.png'
import videoPoster from '../../../../assets/images/TeacherBanner.png'
import prof1 from '../../../../assets/images/profile.png'
import prof2 from '../../../../assets/images/teachers3.png'
import axios from 'axios';
import Comment_section from './comment_section';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { DeleteOutline } from '@mui/icons-material';
import { Pagination, Autoplay } from 'swiper/modules';
import { InsertEmoticonRounded } from '@mui/icons-material';
import moment from 'moment';
import { useSnackbar } from 'notistack'

export default function TimelineTab() {
  

  const {coummunity_name} = useParams()
  
  console.log(coummunity_name,"fvgjhkk");
  
    
    const [Open, setOpen] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);
    const [isActive2, setIsActive2] = React.useState(false);
    const [isActive3, setIsActive3] = React.useState(false);
    const [timeline, setTimeline] = React.useState([])
    const [community, setCommunity] = React.useState([])
    const [activeComments, setActiveComments] = React.useState(new Array(timeline.length).fill(false))
  const [deleteid, setDeleteId] = React.useState('');
    const { enqueueSnackbar } = useSnackbar()
  const[View,setView]=React.useState('')

    let navigate = useNavigate()

    React.useEffect(() => {
        getTimeline()
    }, [coummunity_name])

    const getTimeline = () => {
       
    
        
        axios.get(process.env.REACT_APP_BASE_URL + `Post/getCommunityPost/${coummunity_name}`)
            .then((res) => {
                console.log(res.data,"success");
                
                setTimeline(res.data.posts);
            })
            .catch((error) => {
                console.log(error, "error in Timeline");
            })
    }

    





    const handleOpenModal = () => {
        setOpen(true);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const like = () => {
        setIsActive(!isActive);
    };
    const comment = () => {
        setIsActive2(!isActive2);
    };
    const comment3 = () => {
        setIsActive3(!isActive3);
    };
    const handleNavigate = () => {
        navigate('/podcast-details');
    }


     // Function to toggle comments for a specific post
     const toggleComments = (index) => {
        const newActiveComments = [...activeComments];
        newActiveComments[index] = !newActiveComments[index];
        setActiveComments(newActiveComments);
    };
    const handleOpen = (id) => {
        setDeleteId(id)
        setView(true)
    }
    const handleClose = () => {
        setDeleteId('')
        setView(false);
    };
    const handleDeleteService = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `Post/DeletePost/${deleteid}`)
            .then((res) => {
                enqueueSnackbar("Post deleted successfully", { variant: 'success' })
               getTimeline()
                handleClose()
            })
            .catch((error) => {
                console.log(error);
            })
    }


    return (
        <Box className='community_timeline'>
            {timeline && timeline.map((item,index) => (
                <Box className='timeline_box' key={index}>
                    <Box className='positionRel'>
                        <Box sx={{ display: 'flex', justifyContent: 'end',alignItems:'center',marginTop:'30px',marginRight:'50px', }}>

                            <DeleteOutline className='icons text-grey'onClick={() => handleOpen(item._id)} />
                        </Box>
                        {item.additionalimages.length > 0 ? (
                            <Swiper
                                spaceBetween={30}
                                pagination={{ clickable: true }}
                                autoplay={{ delay: 5000, disableOnInteraction: false }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper p-0"
                            >
                            {item.additionalimages.map((img, imgIndex) => (
                                <SwiperSlide key={imgIndex}>
                                    <img src={process.env.REACT_APP_BASE_URL + img} className='w100' alt={`slide-${imgIndex}`} />
                                </SwiperSlide>
                            ))}
                            </Swiper>
                        ) : (
                            <Swiper>
                                <SwiperSlide>
                                    <React.Fragment>
                                        <video
                                            loop
                                            muted
                                            width='100%'
                                            poster={process.env.REACT_APP_BASE_URL + item.image}
                                        ></video>
                                        {item.Video ? 
                                        <Button disableRipple onClick={() => handleOpenModal(item.Video[0])} className='playbtn'>
                                            <img src={playBtn} alt="Play Button" />
                                        </Button> : null}
                                    </React.Fragment>
                                </SwiperSlide>
                            </Swiper>
                        )}
                    </Box>
                    <Box className='p-20'>
                        <Box className="d-flex mb-20 flex-wrap">
                                                {/* <img src={process.env.REACT_APP_BASE_URL + `${comunityName.profile}`} width={60} className='mr-10 userImg' alt="User" /> */}
                            <Box>
                                <Typography component='h6' variant='body1' className='fz-14 text_black semibold'>
                                    {coummunity_name}
                                </Typography>
                                <Typography component='span' variant='body1' className='fz-14 text_grey fw-400'>
                                    {moment(item.createdAt).fromNow()} <i className="fa-solid fa-earth-americas"></i>
                                </Typography>
                                </Box>
                            </Box>
                            
                        <Typography component='span' variant='body1' className='fz-14 text_black letter_sp fw-400'>
                            {item.description}
                        </Typography>
                    </Box>
                    <Divider /> 
                    <Box className=''>

                                            <Box>
                                                <Box className="p-20">
                                                    <Button
                                                        disableRipple
                                                        className='likebtn btn text_black letter_sp mr-20'
                                                    >
                                                        <i class="fa-solid fa-thumbs-up text_primary fz-18 mr-10"></i>
                                                        {item.likes ? item.likes.length : '0'}
                                                    </Button>
                                                    <Button disableRipple className='likebtn btn text_black letter_sp mr-20' onClick={() => { toggleComments(index) }}><i class="fa-regular fa-message text_primary fz-16 mr-10"></i> {item.totalCommentsAndReplies}</Button>
                                                </Box>
                                                <Box className={activeComments[index] ? 'comment-collapse' : 'comment-collapse comment-close'} >
                                                    <Comment_section data={item} getTimeLine={getTimeline} />
                                                </Box>
                                                
                            
                                            </Box>

                                        </Box>                   
                    {/* <Box className='mb-20'>
                        <Box>
                            <Box className={isActive3 ? 'comment-collapse' : 'comment-collapse comment-close'} >
                                <Comment_section data={item} getTimeLine={getTimeline} />
                            </Box>
                            <Button disableRipple className='likebtn btn  letter_sp pt-10 mr-20' onClick={() => like(InsertEmoticonRounded._id)}>
                                <i class="fa-solid fa-thumbs-up text_primary fz-18 mr-10"></i>
                                <Typography className='text_black fz-14 semibold'>{item.likes.length}</Typography>
                            </Button>
                            <Button disableRipple className='likebtn btn  letter_sp pt-10 mr-20' onClick={() => comment(item._id)}>
                                <i class="fa-regular fa-message text_primary fz-16 mr-10"></i>
                                <Typography className='text_black fz-14 semibold'>{item.comments.length}</Typography>
                            </Button>
                        </Box>

                    </Box> */}
                </Box>
            ))}

            <BootstrapDialog
                onClose={handleCloseModal}
                aria-labelledby="customized-dialog-title"
                open={Open}
                fullWidth
                maxWidth="xl"
                className='fullscreen_modal'
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white',
                        zIndex: 1
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Box>
                    <iframe width="100%" height="600px" src="https://www.youtube.com/embed/v7AYKMP6rOE" frameborder="0" allowfullscreen></iframe>
                </Box>
            </BootstrapDialog>
            <Dialog open={View} keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    className='custom_modal'>
                    <DialogTitle>Would you like to delete This Post?</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleClose} className='cancel_btn'>No</Button>
                        <Button onClick={handleDeleteService} className='theme-btn'>Yes</Button>
                    </DialogActions>
                </Dialog>
        </Box>
    )
}